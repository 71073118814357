import { GetterTree, MutationTree, ActionTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'


interface presentationData {
    /**
     * This should have been generated automatically by
     * the dtsgenerator package we used to create the ~/utils/api_types/index.d.ts
     * The problem is it seems it's not working anymore since we split the
     * openapi spec in multiple files.
     * Maybe we'll find a way to solve that?
     * For now we're doing it manually.
     * Btw, let put a TODO here, just as a reminder.
     */
    plant_id: number,
    chart: {
        load_power: [
            {
                datetime: string,
                value: number,
                unit: string
            }
        ],
        grid_exchange_power: [
            {
                datetime: string,
                value: number,
                unit: string
            }
        ],
        pv_power: [
            {
                datetime: string,
                value: number,
                unit: string
            }
        ]
    },
    info: {
        produced_energy: {
            value: number,
            unit: string
        },
        saved_co2: {
            value: number,
            unit: string
        },
        saved_tpp: {
            value: number,
            unit: string
        }
    }
}
type presentationDataResponse = presentationData[]

interface presentationInfoSummaryResponse {
    /**
     * NB: same TODO as presentationData above
     */
    produced_energy: {
        value: number,
        unit: string
    },
    saved_co2: {
        value: number,
        unit: string
    },
    saved_tpp: {
        value: number,
        unit: string
    }
}


export const state = () => ({
    infoSummary: {} as presentationInfoSummaryResponse,
    data: [] as presentationDataResponse
})

export type PresentationState = ReturnType<typeof state>

export const getters: GetterTree<PresentationState, RootState> = {
    plantData (state) {
        /**
         * get chart data for a given plant
         */
        return (plantId: number | string) => {
            return state.data.filter(d => d.plant_id === plantId)[0]
        }
    }
}

export const mutations: MutationTree<PresentationState> = {
    setInfoSummary (state, infoSummary) {
        state.infoSummary = infoSummary
        // Vue.set(state, infoSummary, infoSummary)
    },
    setData (state, data) {
        state.data = data
    }
}

export const actions: ActionTree<PresentationState, RootState> = {
    async getInfoSummary ({ commit }) {
        const url = apiUrl({ endpoint: 'getPresentationInfoSummary' })
        try {
            const infoSummary = await this.$axios.$get(url)
            commit('setInfoSummary', infoSummary)
        } catch (err) { console.error(err) }
    },
    async getData ({ commit }, params) {
        const url = apiUrl({ endpoint: 'getPresentationData', params })
        try {
            const data = await this.$axios.$get(url)
            commit('setData', data)
        } catch (err) { console.error(err) }
    }
}
