import { GetterTree, MutationTree, ActionTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'
import jwt from '~/utils/jwt'

const getUser = () => {
    try {
        const user = localStorage.getItem('user')
        if (user)
            return JSON.parse(user)
        return undefined
    } catch (err) {
        console.error('Error while parsing user object from localStorage:', err)
        return undefined
    }
}

interface UserPreference {
    // TODO move in types
    code: string,
    content: any // JSON
}
const getUserPreferences = (): Array<UserPreference> | undefined => {
    try {
        const preferences = localStorage.getItem('userPreferences')
        if (preferences)
            return JSON.parse(preferences)
        return undefined
    } catch (err) {
        console.error('Error while parsing userPreferences object from localStorage:', err)
        return undefined
    }
}

export const state = () => ({
    token: localStorage.getItem('access_token') || null,
    user: getUser() || null,
    preferences: getUserPreferences() || [] as Array<UserPreference> | null
})

export type AuthState = ReturnType<typeof state>

export const getters: GetterTree<AuthState, RootState> = {
    loggedIn(state) {
        return state.token !== null
    },
    tokenHasExpired (state) {
        if (!state.token) return true
        return jwt.hasExpired(state.token)
    },
    isEndUser (state) {
        try {
            return state.user.role === 'end_user'
        } catch (err) {}
        return false
    },
    isWorker (state) {
        try {
            return state.user.role === 'worker'
        } catch(err) {}
        return false
    },
    getPreference (state) {
        return (code: string) => {
            let res = undefined
            if (state.preferences)
                res = state.preferences.find(i => i.code === code)
            if (res) {
                try {
                    return res.content
                } catch (err) {
                    console.error(err)
                }
            }
            return res
        }
    }
}

export const mutations: MutationTree<AuthState> = {
    setToken(state, token) {
        state.token = token
    },
    destroyToken(state) {
        state.token = null
    },
    setUser(state, user) {
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
    },
    unsetUser(state) {
        localStorage.removeItem('user')
        state.user = null
    },
    setPreferences(state, preferences: Array<UserPreference>) {
        localStorage.setItem('userPreferences', JSON.stringify(preferences))
        state.preferences = preferences
    },
    // setPreference(state, preference: UserPreference) {
    //     if (!state.preferences)
    //         state.preferences = []
        
    //     if (state.preferences.length === 0) {
    //         state.preferences.push(preference)
    //         return
    //     }
        
    //     // check if pref with given code exists
    //     const idx = state.preferences.findIndex(i => i.code === preference.code)
    //     if (idx > -1) {
    //         state.preferences[idx].content = preference.content
    //     } else {
    //         state.preferences.push(preference)
    //     }

    // },
    unsetPreferences(state) {
        localStorage.removeItem('userPreferences')
        state.preferences = null
    }
}

export const actions: ActionTree<AuthState, RootState> = {
    async retrieveToken(context, credentials) {
        let url = apiUrl({ endpoint: 'getToken' })
        const res = await this.$axios.$post(
            url,
            {
                api_user: {
                    username: credentials.username,
                    password: credentials.password
                }
            }
        )
        const token = res.access
        localStorage.setItem('access_token', token)
        context.commit('setToken', token)
        this.$axios.setToken(token, 'Bearer')

        // get user and put in state
        url = apiUrl({ endpoint: 'getLoggedUser' })
        const user = await this.$axios.$get(url)
        localStorage.setItem('user', JSON.stringify(user))
        context.commit('setUser', user)

        context.dispatch('getPreferences')
        
        return res
    },
    async getPreferences ({ commit }) {
        const url = apiUrl({ endpoint: 'userPreferences' })
        try {
            const preferences: Array<UserPreference> = await this.$axios.$get(url)
            // for (const pref of preferences) {
            //     pref.content = JSON.parse(pref.content)
            // }
            commit('setPreferences', preferences)
        } catch (err) {
            console.error(err)
        }
    },
    async setPreference({ dispatch }, preference: UserPreference) {
        const url = apiUrl({ endpoint: 'userPreferences' })
        try {
            const newOrUpdatedPreference: UserPreference = await this.$axios.$post(url, preference)
            // for (const pref of preferences) {
            //     pref.content = JSON.parse(pref.content)
            // }
            // commit('setPreferences', preferences)
            dispatch('getPreferences')
        } catch (err) {
            console.error(err)
        }
    },
    async deletePreference({ dispatch }, preference: { code: string }) {
        const url = apiUrl({
            endpoint: 'userPreference',
            params: { code: preference.code }
        })
        try {
            await this.$axios.$delete(url)
            dispatch('getPreferences')
        } catch (err) {
            console.error(err)
        }
    },
    destroyToken(context) {
        if (context.getters.loggedIn) {
            localStorage.removeItem('access_token')
            context.commit('destroyToken')
            this.$axios.setToken(false)
            context.commit('unsetUser')
            context.commit('unsetPreferences')
        }
    },
    setAuthHeader(context, token) {
        this.$axios.setToken(token, 'Bearer')
    },
    logout ({ commit, dispatch }) {
        dispatch('destroyToken')
        commit('cleanIndexState', null, { root: true }) // root mutation
        // @ts-ignore (ts could not find $nuxt)
        $nuxt.$router.push({ path: '/logout' })
    }
}
