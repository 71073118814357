import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface downtimesFromApi {
    id: number,
    plant_id: number,
    start: string,
    stop: string,
    description: string,
    energy_lost: number,
    money_lost: number,
    estimated_repair_cost: number,
    percentage_down: number,
    insurance_claim: boolean,
    created_by_id: number,
    downtime_id: number,
    created_at: string,
    updated_at: string,
    time_report: string,
    time_intervention: string,
    cause: string,
}

interface downtimeTypeFromApi {
    label: string,
    description: string,
}

interface suggestionDowntimesFromApi {
    plant_id: number,
    start_downtime: string,
    stop_downtime: string,
    calculations: object[],
    exist_in_db: boolean,
    id_downtime: number,
}

interface suggestionLostFromApi {
    plant_id: number,
    start_downtime: string,
    stop_downtime: string,
    estimated_lost: number,
    exist_in_db: boolean,
    id_downtime: number,
}

export const state = () => ({
    causes: [],
    dates: null,
    downtimes_data: [] as downtimesFromApi[],
    downtime_data: [] as downtimesFromApi[],
    plants_downtimes : [],
    suggestionDowntimes: [] as suggestionDowntimesFromApi[],
    suggestionLost: [] as suggestionLostFromApi[],
})

export type DowntimesState = ReturnType<typeof state>

export const getters: GetterTree<DowntimesState, RootState> = {
    downtimeById (state) {
        return (downtimeId: number) => state.downtimes_data.find(m => m.id == downtimeId)
    },
}

export const mutations: MutationTree<DowntimesState> = {
    setRangeDates (state, dates) {
        state.dates = dates
    },
    setDowntimeCauses (state, causes) {
        state.causes = causes
    },
    setPlantsDowntimes (state, plants_downtimes) {
        state.plants_downtimes = plants_downtimes
    },
    setDowntimes (state, downtimes_data) {
        state.downtimes_data = downtimes_data
    },
    setDowntime (state, downtime_data) {
        state.downtime_data = downtime_data
    },
    setSuggestionDowntime (state, suggestionDowntimes) {
        state.suggestionDowntimes = suggestionDowntimes
    },
    suggestionLost (state, suggestionLost) {
        state.suggestionLost = suggestionLost
    },
    addOrReplaceDowntime (state, downtime) {
        state.downtimes_data = [
            ...state.downtimes_data.filter(element => element.id !== downtime.id),
            downtime
        ]
    },
    delDowntime (state, downtimeId) {
        const idx = state.downtimes_data.findIndex((m) => m.id == downtimeId)
        if (idx > -1){
            state.downtimes_data.splice(idx, 1);
        }
    },
    delSuggestionDowntime (state, downtime) {
        const idx = state.suggestionDowntimes.indexOf(downtime)
        state.suggestionDowntimes.splice(idx, 1);
    },
    setSuggestionToExistInDb(state, downtime) {
        for (let d of state.suggestionDowntimes) {
            if(d.start_downtime == downtime.start && d.stop_downtime == downtime.stop && d.plant_id == downtime.plant_id) {
                d.exist_in_db = true
            }
        }
    }
}

export const actions: ActionTree<DowntimesState, RootState> = {
    async getRangeDates (context,) {
        try {
            context.commit('setRangeDates')
        } catch (err) {
            console.error(err)
        }
    },
    async getDowntimes (context, params) {
        const url = apiUrl({ endpoint: 'getDowntimes', params: params })
        try {
            const downtimes_data = await this.$axios.$get(url)
            context.commit('setDowntimes', downtimes_data)
        } catch (err) {
            console.error(err)
        }
    },
    async getDowntime (context, downtimeId) {
        const url = apiUrl({ endpoint: 'getDowntime', params: { downtimeId } })
        try {
            context.commit('setDowntime', {})
            const downtime = await this.$axios.$get(url)
            context.commit('setDowntime', downtime)
        } catch (err) {
            console.error(err)
        }
    },
    async delDowntime (context, downtimeId) {
        const url = apiUrl({ endpoint: 'getDowntime', params: { downtimeId } })
        try {
            await this.$axios.$delete(url)
            context.commit('delDowntime', downtimeId)
        } catch (err) {
            console.error(err)
        }
    },
    async createDowntime (context, downtime) {
        const url = apiUrl({ endpoint: 'addDowntime', params: {} })
        try {
            const res = await this.$axios.$post(url, { downtime: downtime })
            context.commit('addOrReplaceDowntime', res)
            context.commit('delSuggestionDowntime', downtime)
        } catch (err) {
            console.error(err)
        }
    },
    async updateDowntime (context, {downtimeId, downtime}) {
        const url = apiUrl({ endpoint: 'getDowntime', params: { downtimeId } })
        try {
            context.commit('setDowntime', {})
            await this.$axios.$put(url, { downtime: downtime })
            context.commit('addOrReplaceDowntime', downtime)
        } catch (err) {
            console.error(err)
        }
    },
    async getCauses (context) {
        const url = apiUrl({ endpoint: 'getDowntimeCauses' })
        try {
            const causes = await this.$axios.$get(url)
            context.commit('setDowntimeCauses', causes)
        } catch (err) {
            console.error(err)
        }
    },
    async getSuggestDowntimes (context, params) {
        const url = apiUrl({ endpoint: 'getSuggestDowntimes', params: params })
        context.commit('setSuggestionDowntime', null)
        try {
            const suggestionDowntimes = await this.$axios.$get(url)
            context.commit('setSuggestionDowntime', suggestionDowntimes)
        } catch (err) {
            console.error(err)
        }
    },
    async getSuggestionLost (context, params) {
        const url = apiUrl({ endpoint: 'getSuggestLost', params: params })
        try {
            const suggestionDowntimes = await this.$axios.$get(url)
            context.commit('suggestionLost', suggestionDowntimes)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantsDowntimes (context, params) {
        const url = apiUrl({ endpoint: 'getPlantsDowntime', params: params })
        try {
            const plants_downtimes = await this.$axios.$get(url)
            context.commit('setPlantsDowntimes', plants_downtimes)
        } catch (err) {
            console.error(err)
        }
    },
    async exportDowntimes (context, params) {
        const url = apiUrl({ endpoint: 'getReportsK451', params })
        try {
            const csv = await this.$axios.$get(url, { responseType: 'blob' })
            const blob = new Blob([csv], { type:'text/csv'})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            const date = new Date(params.year, params.month -1, 1);
            link.download = `K451 ${date.toLocaleString('default', { month: 'short' })} ${params.year}.xlsx`
            link.click()
        } catch (err) {
            console.error(err)
        }
    }
}
