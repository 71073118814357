import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface maintenanceRequestFromApi {
    id: number,
    plant_id: number,
    date: string,
    description: string,
    maintenance_type: string,
    requester_id: number,
    assigned_to_id: number,
    closed: boolean,
    estimated_time: number,
    priority: number,
    saved_for_later: boolean,
    maintenance_reports?: any[],
}

interface maintenanceTypeFromApi {
    label: string,
    description: string,
}

type PlantsWorkersListFromApi = Array<{
    id: number,
    username: string,
    role: string,
    name: string,
    surname: string,
    email: string,
    annotation: string,
    can_check_plants: true,
    phone_numbers: Array<{
        id: number,
        number: string,
        local_number: string,
        phone_type: string
    }>,
    plants: Array<{
        id: number,
        name: string,
        description: string
    }>
}>

export const state = () => ({
    maintenanceTypes: [] as maintenanceTypeFromApi[],
    requests: [] as maintenanceRequestFromApi[],
    plantsWorkersList: [] as PlantsWorkersListFromApi
})

export type MaintenanceRequestsState = ReturnType<typeof state>

export const getters: GetterTree<MaintenanceRequestsState, RootState> = {
    requestById (state) {
        return (requestId: number) => state.requests.find(m => m.id == requestId)
    },
}

export const mutations: MutationTree<MaintenanceRequestsState> = {
    setMaintenanceTypes (state, maintenanceTypes) {
        state.maintenanceTypes = maintenanceTypes
    },
    setRequests (state, requests) {
        state.requests = requests
    },
    addOrReplaceRequest (state, request) {
        state.requests = [
            ...state.requests.filter(element => element.id !== request.id),
            request
        ]
    },
    delRequest (state, requestId) {
        const idx = state.requests.findIndex((m) => m.id == requestId)
        if (idx > -1){
            state.requests.splice(idx, 1);
        }
    },
    setPlantsWorkersList (state, plantsWorkersList) {
        state.plantsWorkersList = plantsWorkersList
    }
}

export const actions: ActionTree<MaintenanceRequestsState, RootState> = {
    async getRequests (context, page=null) {
        // TODO verificare size con parte backend (sembra che adesso
        // mandi sempre size: 50)
        const params = page ? { page, size: 50 } : {}
        const url = apiUrl({ endpoint: 'getMaintenanceRequests', params })
        try {
            const requests = await this.$axios.$get(url)
            context.commit('setRequests', requests)
        } catch (err) {
            console.error(err)
        }
    },
    async getRequest (context, requestId) {
        const url = apiUrl({ endpoint: 'getMaintenanceRequest', params: { requestId } })
        try {
            const request = await this.$axios.$get(url)
            context.commit('addOrReplaceRequest', request)
        } catch (err) {
            console.error(err)
        }
    },
    async delRequest (context, requestId) {
        const url = apiUrl({ endpoint: 'getMaintenanceRequest', params: { requestId } })
        try {
            await this.$axios.$delete(url)
            context.commit('delRequest', requestId)
        } catch (err) {
            console.error(err)
        }
    },
    async createRequest (context, request) {
        const url = apiUrl({ endpoint: 'getMaintenanceRequests', params: {} })
        try {
            await this.$axios.$post(url, { maintenance: request })
            await context.dispatch('getRequests')
        } catch (err) {
            console.error(err)
        }
    },
    async updateRequest (context, {requestId, request}) {
        const url = apiUrl({ endpoint: 'getMaintenanceRequest', params: { requestId } })
        try {
            await this.$axios.$put(url, { maintenance: request })
            await context.dispatch('getRequests')
        } catch (err) {
            console.error(err)
        }
    },
    async getMaintenanceTypes (context) {
        const url = apiUrl({ endpoint: 'getMaintenanceTypes' })
        try {
            const maintenanceTypes = await this.$axios.$get(url)
            context.commit('setMaintenanceTypes', maintenanceTypes)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantsWorkersList ({ commit }) {
        const url = apiUrl({ endpoint: 'getPlantsWorkersList'})
        try {
            const plantsWorkersList = await this.$axios.$get(url)
            commit('setPlantsWorkersList', plantsWorkersList)
        } catch (err) {
            console.error(err)
        }
    }
}
