import Vue from 'vue'
import { ToastProgrammatic as Toast, NotificationProgrammatic as Notification } from 'buefy'

const logic = (errMsg) => {
    if (typeof errMsg === 'string' && errMsg.length < 78)
        Toast.open({
            message: errMsg,
            type: 'is-danger'
        })
    else
        Notification.open({
            duration: 7500,
            message: `${errMsg}`,
            position: 'is-top',
            type: 'is-danger',
            hasIcon: false
        })
}

export const ErrorHandler = function (errMsg) {
    logic(errMsg)
}

Vue.prototype.$error = function (errMsg) {
    logic(errMsg)
}
