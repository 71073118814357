import { MutationTree } from 'vuex'

import { RootState } from '~/store'

export const state = () => ({
    newNotifications: [] as Array<any>  // new notifications coming from websocket connection
})

export type NotificationsState = ReturnType<typeof state>

export const mutations: MutationTree<NotificationsState> = {
    addNewNotification (state, notification) {
        state.newNotifications.push(notification)
    },
    emptyNotifications (state) {
        state.newNotifications = []
    }
}
