<template>
    <section>
        <nuxt />

        <my-footer class="my-footer" />
    </section>
</template>

<script>
import MyFooter from '~/components/layout/Footer'

export default {
    components: {
        MyFooter
    }
}
</script>
