import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface display {
    id: number,
    device: string,
    register: string,
    unit: string
    chart_type?: 'line' | 'area' | 'bar',
    style?: 'solid' | 'dashed',
    color?: string
}

interface plantType {
    name: string,
    code: number
}

interface plant {
    id: number,
    name: string,
    description: string,
    plant_type: plantType,
    location: {
        country: string,
        region: string,
        geodata: {
            longitude: number,
            latitude: number
        }
    },
    command_api: {
        token: string,
        host: string
    },
    display: {
        chart: display[],
        sudden: display[],
        sudden_detail: display[]
    },
    alarms: any[],
}

export const state = () => ({
    plants: [] as plant[],
    plantTypes: [] as plantType[]
})

export type PlantManagementState = ReturnType<typeof state>

export const getters: GetterTree<PlantManagementState, RootState> = {
    getPlantCompleteName: (state) => (plant: any) => {
        if (!isNaN(plant))
            plant = state.plants.find(p => p.id === plant)
        return plant ? `${plant.name} (${plant.description})` : 'Plant not found' + plant
    },  
}

export const mutations: MutationTree<PlantManagementState> = {
    setPlants (state, plants) {
        state.plants = plants
    },
    delPlant (state, plantId) {
        const idx = state.plants.findIndex((p) => p.id == plantId)
        if (idx > -1){
            state.plants.splice(idx, 1);
        }
    },
    addOrReplacePlant (state, plant) {
        state.plants = [
            ...state.plants.filter(p => p.id !== plant.id),
            plant
        ]
    },
    setPlantTypes (state, plantTypes) {
        state.plantTypes = plantTypes
    }
}

export const actions: ActionTree<PlantManagementState, RootState> = {
    async getPlants (context) {
        const url = apiUrl({ endpoint: 'getPlants' })
        try {
            const plants = await this.$axios.$get(url)
            context.commit('setPlants', plants)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlant (context, plantId) {
        const url = apiUrl({ endpoint: 'getPlantDetail', params: { plantId } })
        try {
            const plant = await this.$axios.$get(url)
            context.commit('addOrReplacePlant', plant)
        } catch (err) {
            console.error(err)
        }
    },
    async delPlant (context, plantId) {
        const url = apiUrl({ endpoint: 'getPlantDetail', params: { plantId } })
        try {
            await this.$axios.$delete(url)
            context.commit('delPlant', plantId)
        } catch (err) {
            console.error(err)
        }
    },
    async createPlant (context, newPlant) {
        const url = apiUrl({ endpoint: 'getPlants' })
        try {
            const plant = await this.$axios.$post(url, { plant: newPlant })
            context.commit('addOrReplacePlant', plant )
        } catch (err) {
            console.error(err)
        }
    },
    async updatePlant (context, {plantId, editedPlant}) {
        const url = apiUrl({ endpoint: 'getPlantDetail', params: { plantId } })
        try {
            const plant = await this.$axios.$put(url, { plant: editedPlant })
            context.commit('addOrReplacePlant', plant )
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantTypes(context) {
        const url = apiUrl({ endpoint: 'getPlantTypes' })
        try {
            const types = await this.$axios.$get(url)
            context.commit('setPlantTypes', types )
        } catch (err) {
            console.error(err)
        }
    }
}
