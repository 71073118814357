import { GetterTree, ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
import { ToastProgrammatic as Toast } from 'buefy'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

/** ==================== Types and Inferfaces ==================== */
type registerName = 'dc_voltage' | 'dc_current' | 'dc_power' |
                    'ac_voltage' | 'ac_current' | 'ac_power' |
                    'internal_temp' | 'sink_temp' | 'status' |
                    'errors' | 'warnings' | string
interface dataFromSocket {
    id: number | string | null,
    content: {
        STATUS: {
            name: string,  // e.g. inverter_1_2
            datetime: string,
            registers: [{
                unit_of_measure: string | null,
                description: string | null,
                name: registerName,
                value: number
            }],
            alarms: [any],  // TODO: define alarms
        }
    },
    plant_id: string,  // e.g. inverter_1_2
    datetime: string,
    device_name: string,
    data_type: string,  // TODO: can we declare it as a type? we need more info on the possible values
    created_at: string | null,
    updated_at: string | null
}

type signleDeviceFromApi = OndaApi.Paths.GetDevices.Responses.$200[number]
interface Device extends signleDeviceFromApi {
    data?: dataFromSocket['content']['STATUS']['registers']
}

interface deviceDetail {
    plant: number,
    name: string,
    type: string,
    registers: [
        {
            name: string,
            description: string | {
                en: string,
                it: string
            }
        }
    ],
    commands: [
        {
            name: string,
            description: string | {
                en: string,
                it: string
            }
        }
    ],
    chart_register: [string]
}
type deviceDetailRes = Array<deviceDetail>
/** ================== End Types and Inferfaces ================== */


export const state = () => ({
    devices: [] as Device[],
})

export type DevicesState = ReturnType<typeof state>

export const getters: GetterTree<DevicesState, RootState> = {
    inverters (state) {
        return state.devices.filter(d => d.type === 'inverters')
    },
    transformers (state) {
        return state.devices.filter(d => d.type === 'transformers')
    },
    weatherStations (state) {
        return state.devices.filter(d => d.type === 'weather_stations')
    },
    genericDevices (state) {
        return state.devices.filter(d => d.type === 'generic_devices')
    },
    siteDevice (state) {
        return state.devices.filter(d => d.name === ':site:')
    },
    stringBoxes (state) {
        return state.devices.filter(d => d.type === 'string_boxes')
    },
    voltageProtections (state) {
        return state.devices.filter(d => d.type === 'voltage_protections')
    },
    cogenerators (state) {
        return state.devices.filter(d => d.type === 'cogenerators')
    },
    allOtherDevices (state) {
        return state.devices.filter(d => {
            const exclude = ['inverters', 'transformers', 'weather_stations', 'generic_devices', 'cogenerators', ':site:']
            return exclude.indexOf(d.type) < 0
        })
    }
}

export const mutations: MutationTree<DevicesState> = {
    setDevices (state, devices) {
        state.devices = devices
    },
    updateDeviceData (state, inputdata: dataFromSocket | string) {
        let data: dataFromSocket
        if (typeof inputdata === 'string') {
            data = JSON.parse(inputdata)
        } else {
            data = inputdata
        }
        
        const deviceIdx = state.devices.findIndex(device => device.name === data.device_name)
        if (deviceIdx < 0) return

        const device = state.devices[deviceIdx]
        // console.log('aggiorno il divice', device.name)
        Vue.set(device, 'updated_at', data.datetime)
        Vue.set(device, 'data', data.content.STATUS.registers)
    }
}

export const actions: ActionTree<DevicesState, RootState> = {
    async getDevices ({ commit }, plantId) {
        const url = apiUrl({ endpoint: 'getDevices', params: { plantId } })
        try {
            const devices: OndaApi.Paths.GetDevices.Responses.$200 = await this.$axios.$get(url)
            commit('setDevices', devices)
        } catch (err) {
            console.error(err)
        }
    },
    async getDeviceDetail (context, { plantId, deviceName }): Promise<deviceDetailRes | undefined> {
        const url = apiUrl({
            endpoint: 'getDeviceDetail',
            params: { plantId, deviceName }
        })
        try {
            const deviceDetail: deviceDetailRes = await this.$axios.$get(url)
            return deviceDetail
        } catch (err) {
            console.error(err)
            return undefined
        }
    },
    async sendCommand (context, { plantId, deviceName, commandName }) {
        const url = apiUrl({
            endpoint: 'sendDeviceCommand',
            params: { plantId, deviceName }
        })
        try {
            await this.$axios.$put(url, { name: commandName })
            Toast.open({
                type: 'is-info',
                message: `Comando <b>${commandName}</b> inviato con successo`
            })
        } catch (err) {
            Toast.open({
                type: 'is-danger',
                message: `Si è verificato un errore nell'invio del comando`
            })
            console.error(err)
        }
    }
}
