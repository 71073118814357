const baseUrl = process.env.webSocketBaseUrl
import { setMsgHandlers } from '~/utils/ws'

const msgHandlers = store => ([
    { channel: 'BottomInfoChannel', callback: data => store.commit('setPlantBottomInfo', data) },
    { channel: 'ChartDataChannel', callback: data => store.commit('pushPlantData', data) },
    { channel: 'BottomInfoDetailChannel', callback: data => store.commit('plantDetail/setPlantBottomInfo', data) },
    { channel: 'DataChannel', callback: data => store.commit('devices/updateDeviceData', data) },
    { channel: 'TableDataChannel', callback: data => store.commit('plantsMonitoring/pushTablePlantData', data) },
    { channel: 'notifications', callback: data => store.commit('notifications/addNewNotification', data) },
])

export default store => {
    const token = store.state.auth.token
    const tokenHasExpired = store.getters['auth/tokenHasExpired']
    
    if (token && !tokenHasExpired) {
        const url = `${baseUrl}/cable?token=${token}`
        const ws = new WebSocket(url)
        setMsgHandlers(ws, msgHandlers(store))
        store.commit('webSocket/setWS', ws)
    }

    store.subscribe((mutation, state) => {
        if (mutation.type === 'auth/setToken') {
            const url = `${baseUrl}/cable?token=${mutation.payload}`
            const ws = new WebSocket(url)
            setMsgHandlers(ws, msgHandlers(store))
            store.commit('webSocket/setWS', ws)
        }
    })
}
