<template>
    <nav>
        <div class="top-bar">
            <button
                v-if="!isWorker"
                class="button menu-button open-menu"
                @click="openMenu">
                <b-icon icon="menu" />
            </button>
            <h1 v-html="pageTitle">{{ pageTitle }}</h1>
        </div>


        <div
            class="dark-bg"
            @click="closeMenu"
            :class="[ menuState === 'closed' ? 'hidden' : '' ]" />
        <section
            class="menu"
            :class="[ menuState === 'closed' ? 'hidden' : '' ]">
            <header>
                <button
                    class="button menu-button close-menu"
                    @click="closeMenu">
                    <b-icon icon="close" />
                </button>
                <nuxt-link
                    :to="{ path: '/'}"
                    @click.native="closeMenu"
                    class="logo-link">
                    <img v-if="isEndUser" class="logo" src="~/assets/logo-end-user.png" alt="Onda logo">
                    <img v-else class="logo" src="~/assets/logo.svg" alt="Onda logo">
                </nuxt-link>
            </header>

            <section class="content">
                <template v-if="!isEndUser && !isWorker">
                    <h3>Ticketing</h3>
                    <ul>
                        <li>
                            <nuxt-link
                                :to="{ path: '/maintenances/requests/'}"
                                @click.native="closeMenu">
                                <b-icon icon="screwdriver" size="is-small"></b-icon>
                                <span>Maintenance requests</span>
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link
                                :to="{ path: '/maintenances/reports/'}"
                                @click.native="closeMenu">
                                <b-icon icon="file-chart" size="is-small"></b-icon>
                                <span>Maintenance reports</span>
                            </nuxt-link>
                        </li>
                    </ul>
                </template>

                <nuxt-link to="/revenues">Revenues</nuxt-link>

                <h3 v-if="user">{{ user.username }}</h3>
                <h3 v-else>User</h3>
                <ul>
                    <li v-if="!isWorker && !isEndUser">
                        <nuxt-link
                            :to="{ path: '/users/'}"
                            @click.native="closeMenu">
                            <b-icon icon="account" size="is-small"></b-icon>
                            <span>Users management</span>
                        </nuxt-link>
                    </li>
                    <li>
                        <nuxt-link
                            :to="{ path: '/plants/'}"
                            @click.native="closeMenu">
                            <b-icon icon="office-building" size="is-small"></b-icon>
                            <span>Plants management</span>
                        </nuxt-link>
                    </li>
                    <hr />
                    <li>
                        <a @click="logout">
                            <b-icon icon="logout" size="is-small"></b-icon>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </section>
        </section>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data () {
        return {
            menuState: 'closed'
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', [
            'isEndUser',
            'isWorker'
        ]),
        pageTitle() {
            return this.$store.state.pageTitle;
        },
        isLoggedIn () {
            return this.$store.getters['auth/loggedIn']
        }
    },
    methods: {
        openMenu () {
            this.menuState = 'open'
        },
        closeMenu () {
            this.menuState = 'closed'
        },
        logout () {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>

<style scoped>
nav > * {
    margin: 0;
    padding: 0;
}

nav {
    /* background: #fff; */
    display: flex;
    padding: .5rem .8rem;
}

.top-bar {
    display: flex;
    align-items: center;
}

.top-bar h1 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-left: 1rem;
}

ul {
    list-style-type: none;
}

li:not(:last-child) {
    margin-bottom: 1rem;
}

.menu-button, .menu-button:hover {
    border: none;
}

.hidden {
    display: none;
}

.dark-bg {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    /* bottom: 0;
    right: 0; */
    width: 100vw;
    height: 100vh;
    z-index: 9;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    min-width: 70vw;
    max-width: 90vw;
    background: #fff;
    z-index: 10;
    padding: 1rem;
    overflow: scroll;
    animation: menu-in .2s;
}
.menu header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.menu .logo {
    max-height: 50px;
}
@keyframes menu-in {
    0% { transform: translateX(-100px); }
    100% { transform: translateX(0); }
}
</style>
