import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface BottomInfoFromWS {
    plant: number,
    label: string,
    unit_of_measure: string,
    datetime: string,
    value: number
}

type Register = OndaApi.Paths.GetPlantData.Responses.$200[number]['datasets'][number]['code']

export const state = () => ({
    plantData: [] as OndaApi.Paths.GetPlantData.Responses.$200,
    plantDetail: {} as OndaApi.Paths.GetPlantDetail.Responses.$200,
    getPlantDetailIsLoading: false,
    bottomInfo: [] as BottomInfoFromWS[],
    weather: null as Object | null,
    selectedRegisters: [] as Array<Register>,
    selectedDates: [] as [Date, Date] | [] // [begin, end]
})

export type PlantDetailState = ReturnType<typeof state>

export const getters: GetterTree<PlantDetailState, RootState> = {
    plantName (state) {
        if (state.plantDetail)
            return `${state.plantDetail.name}`
        return null
    },
    plantDescription (state) {
        if (state.plantDetail)
            return `${state.plantDetail.description}`
        return null
    },
    // plantFullName (state) {
    //     let res = null
    //     if (state.plantData)
    //         state.plantData.description ?
    //         res = `${state.plantData.name} - ${state.plantData.description}`
    //         : res = state.plantData.description
    //     return res
    // },
    plantId (state) {
        return state.plantDetail.id
    }
}

function addRegister (state: PlantDetailState, register: Register) {
    const registerAlreadyAdded = state.selectedRegisters.findIndex(i => i === register) > -1
    if (!registerAlreadyAdded) {
        state.selectedRegisters.push(register)
    }
}

function addPlantDataRegisters (state: PlantDetailState, plantData: OndaApi.Paths.GetPlantData.Responses.$200) {
    for (let item of plantData) {
        for (let dataset of item.datasets) {
            addRegister(state, dataset.code)
        }
    }
}

export const mutations: MutationTree<PlantDetailState> = {
    setPlantDetail (state, plantDetail) {
        state.plantDetail = plantDetail
    },
    setGetPlantDetailIsLoading (state, isLoading) {
        state.getPlantDetailIsLoading = isLoading
    },
    setPlantData (state, plantData) {
        state.plantData = plantData
        addPlantDataRegisters(state, plantData)
    },
    pushPlantData (state, plantData: OndaApi.Paths.GetPlantData.Responses.$200) {
        const item = state.plantData[0]
        if (item) {
            item.datasets = item.datasets.concat(plantData[0].datasets)
        } else {
            state.plantData = plantData
        }
        addPlantDataRegisters(state, plantData)
    },
    setPlantBottomInfo (state, plantBottomInfo: BottomInfoFromWS) {
        if (plantBottomInfo.plant !== state.plantDetail.id)
            return

        const infoIdx = state.bottomInfo.findIndex(i => i.label === plantBottomInfo.label)
        if (infoIdx > -1) {
            // NB, this won't work: state.bottomInfo[infoIdx] = plantBottomInfo
            // bacause of reactivy issues etc.
            // See: https://stackoverflow.com/questions/52132321/array-of-objects-correct-way-of-updating-an-object-within-the-vue-js-ecosystem
            state.bottomInfo.splice(infoIdx, 1, plantBottomInfo)
        } else {
            state.bottomInfo.push(plantBottomInfo)
        }
    },
    setWeather (state, weather: Array<any>) {
        if (weather.length !== 1) {
            console.warn('weather.length !== 1', weather.length)
            return
        }
        // if (weather[0].plant_id !== state.plantDetail.id) {
        //     console.warn('weather does not match plant id', weather[0].plant_id, state.plantDetail.id)
        //     return
        // }
        const weatherObj = weather[0]
        state.weather = {
            id: weatherObj.openweathermap_data.weather[0].id,
            temp: weatherObj.openweathermap_data.main.temp
        }
    },
    setSelectedDates (state, selectedDates: [Date, Date]) {
        state.selectedDates = selectedDates
    },
    cleanState (state) {
        state.plantDetail = {}
        state.plantData = []
        state.bottomInfo = []
        state.selectedRegisters = [],
        state.selectedDates = []
    }
}

// const adjustPlantData = plant => {
//     let data = []
//     data.push(plant.data.ac_power)

//     // TODO compute the right way
//     data.push(plant.data.irradiance)
//     plant.data = data
//     return plant
// }

export const actions: ActionTree<PlantDetailState, RootState> = {
    async getPlantDetail (context, plantId) {
        context.commit('setGetPlantDetailIsLoading', true)
        const url = apiUrl({
            endpoint: 'getPlantDetail',
            params: { plantId }
        })
        try {
            const plantDetail = await this.$axios.$get(url)
            // context.commit('setPlantDetail', adjustPlantData(plantData))
            context.commit('setPlantDetail', plantDetail)
        } catch (err) {
            console.error(err)
        }
        context.commit('setGetPlantDetailIsLoading', false)
    },
    async getPlantData (context, params) {
        context.commit('setGetPlantDetailIsLoading', true)
        const url = apiUrl({ endpoint: 'getPlantsData', params })
        try {
            const plantData: OndaApi.Paths.GetPlantData.Responses.$200 = await this.$axios.$get(url)
            context.commit('setPlantData', plantData)
        } catch (err) {
            console.error(err)
        }
        context.commit('setGetPlantDetailIsLoading', false)
    },
    async addPlantData (context, params) {
        /**
         * Same as getPlantData above, bust it just push the new data
         * to the plantData array, instead of overwrinting
         */
        context.commit('setGetPlantDetailIsLoading', true)
        const url = apiUrl({ endpoint: 'getPlantsData', params })
        try {
            const plantData: OndaApi.Paths.GetPlantData.Responses.$200 = await this.$axios.$get(url)
            context.commit('pushPlantData', plantData)
        } catch (err) {
            console.error(err)
        }
        context.commit('setGetPlantDetailIsLoading', false)
    },
    async getWeather (context, plantId) {
        const url = apiUrl({ endpoint: 'getWeathers', params: { plantId } })
        try {
            const weather = await this.$axios.$get(url)
            context.commit('setWeather', weather)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantBottomInfo (context, plantId) {
        const url = apiUrl({ endpoint: 'getBottomInfo', params: { plantId } })
        try {
            const plantBottomInfo = await this.$axios.$get(url)
            for (var info of plantBottomInfo[0].data) {
                context.commit('setPlantBottomInfo', { ...info, plant: plantBottomInfo[0].plant })
            }
        } catch (err) {
            console.error(err)
        }
    }
}
