import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

export type PlantsMonitoringState = ReturnType<typeof state>

interface newTablePlantDataFromWebSocket {
    plant: number,
    label: string,
    name: string,
    pr: number,
    device_type: string,
    gradation: number,
    datetime: string,
    value: number,
    type: number,
    loss_ratio: number,
    summary: {
      label: string,
      value: number
    }[],
}

export const state = () => ({
    tablePlantsData: [] as OndaApi.Paths.GetTablePlantData.Responses.$200,
    tablePlantData: [] as OndaApi.Paths.GetTablePlantData.Responses.$200
})

export const mutations: MutationTree<PlantsMonitoringState> = {
    setTablePlantsData (state, tablePlantsData: OndaApi.Paths.GetTablePlantData.Responses.$200) {
        state.tablePlantsData = tablePlantsData
    },
    setTablePlantData (state, tablePlantData: OndaApi.Paths.GetTablePlantData.Responses.$200) {
        state.tablePlantData = tablePlantData
    },
    pushTablePlantData (state, data: newTablePlantDataFromWebSocket) {
        console.log('pushTablePlantData has been triggered with the following data', JSON.stringify(data))
        /**
         * Push incoming plat data from websocket to the right plant,
         * in the plant's dataset with the same label (if there's already do nothing)
         */

        let stateData = []
        const datumIdxPlants = state.tablePlantsData.findIndex(i => i.plant === data.plant)
        if (datumIdxPlants >= 0)
          stateData.push(state.tablePlantsData[datumIdxPlants])
        const datumIdxPlant = state.tablePlantData.findIndex(i => i.plant === data.plant)
          if (datumIdxPlant >= 0)
            stateData.push(state.tablePlantData[datumIdxPlant])

        if (data.datetime && data.type){
          for(let stateDatum of stateData){
            // See if the stateDatum already has a dataset with the given label
            // and push data accordingly
            let datasetIdx = stateDatum.datasets.findIndex(i => i.label === data.label)
            if (datasetIdx < 0) {
                console.log('Data non requested.')
                continue;
            }

            stateDatum.datasets[datasetIdx].dataset.push({
                datetime: data.datetime,
                value: data.value,
                type: data.type,
                pr: data.pr,
                gradation: data.gradation,
                loss_ratio: data.loss_ratio,
            });
          }
        }
        if(! data.summary){
          console.log('-~-');
          return;
        }
              for(let stateDatum of stateData){
          let datasetIdx = stateDatum.datasets.findIndex(i => i.label === data.label)
          if (datasetIdx < 0) {
              console.log('Summary non requested.')
              continue;
          }
          stateDatum.datasets[datasetIdx].summary = data.summary
        }
        console.log('-~-')
    },
    cleanIndexState (state) {
        /**
         * Reset all the elements in the state
         * to their initial values
         */
        state.tablePlantsData = []
    }
  }
export const actions: ActionTree<PlantsMonitoringState, RootState> = {
  async getTablePlantsData(context, params) {
        // Eva-Plants-API's devices groups should made that obsolete.
        // Since the computation for the main plants monitoring page should
        // be way faster.
        //
        // let comulativeTablePlantsData = [];
        // let plantsId = params.plantsId || [params.plantId]
        // for(let plantId of plantsId){
        //   params.plantId = plantId;
        //   const url = apiUrl({ endpoint: 'getTablePlantsData', params: params || {} })
        //   try {
        //       let tablePlantsData: OndaApi.Paths.GetTablePlantData.Responses.$200 = await this.$axios.$get(url)
        //       comulativeTablePlantsData.push(...tablePlantsData);
        //       context.commit('setTablePlantsData', ...comulativeTablePlantsData)
        //   } catch (err) {
        //       console.error(err)
        //   }
        // }
        // context.commit('setTablePlantsData', tablePlantsData)
        const url = apiUrl({ endpoint: 'getTablePlantsData', params: params || {} })
        try {
            const tablePlantsData: OndaApi.Paths.GetTablePlantData.Responses.$200[] = await this.$axios.$get(url)
            context.commit('setTablePlantsData', tablePlantsData)
        } catch (err) {
            console.error(err)
        }
  },
  async getTablePlantData (context, params) {
      const url = apiUrl({ endpoint: 'getTablePlantData', params: params || {} })
      try {
          const tablePlantData: OndaApi.Paths.GetTablePlantData.Responses.$200 = await this.$axios.$get(url)
          context.commit('setTablePlantData', tablePlantData)
      } catch (err) {
          console.error(err)
      }
  },
}
