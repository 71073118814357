import { MutationTree } from 'vuex'

export const state = () => ({
    ws: null as WebSocket | null
})

export type WebSocketState = ReturnType<typeof state>

export const mutations: MutationTree<WebSocketState> = {
    setWS (state, ws: WebSocket) {
        state.ws = ws
    }
}
