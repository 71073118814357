import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { apiUrl } from '~/utils/url'
import createWebSocket from './plugins/createWebSocket'
import Vue from 'vue'


type SinglePlantsListItem = OndaApi.Paths.GetPlantsList.Responses.$200[number]
interface Plant extends SinglePlantsListItem {
    data: OndaApi.Paths.GetPlantData.Responses.$200[number],
    bottomInfo: {
        label: string,
        unit_of_measure: string,
        value: string
    }[],
    weather?: {
        id: number,
        temp: number
    },
    status: 'error' | 'warning' | null | undefined
}

interface newPlantDataFromWebSocket {
    plant: number,
    label: string,
    unit_of_measure: string,
    datetime: string,
    value: number
}

// NB: plants has type any, bc type OndaApi.Paths.GetPlantsList.Responses.$200
// resulted in an error such as "type ... does not have properties plant and bottomInfo"
// TODO - fix with better typing
const adjustPlantsStructure = (plants: any[]): Plant[] => {
    return plants.map(plant => {
        plant.data = {
            events: [],
            datasets: []
        }
        plant.bottomInfo = []
        return plant
    })
}

export const plugins = [createWebSocket]

export const state = () => ({
    pageTitle: '',
    plants: [] as Array<Plant>,
    plantsData: [] as OndaApi.Paths.GetPlantData.Responses.$200
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
    plantsNeedToBeRefreshed (state) {
        if (state.plants.length > 0)
            return false
        return true
    },
    getPlantCompleteName: (state) => (plant: any) => {
        if (!isNaN(plant))
            plant = state.plants.find(p => p.id === plant)
        return plant ? `${plant.name} (${plant.description})` : 'Plant not found'
    },  
}

export const mutations: MutationTree<RootState> = {
    setPageTitle (state, title) {
        state.pageTitle = title
    },
    setPlants (state, plants) {
        state.plants = adjustPlantsStructure(plants)
    },
    setPlantBottomInfo (state, data) {
        const plantId = data.plant
        const plantIndex = state.plants.findIndex(i => i.id === plantId)
        if (plantIndex === -1)
            return
        
        const plant: Plant = state.plants[plantIndex]

        if (!plant.bottomInfo) plant.bottomInfo = []
        const infoItemIndex = plant.bottomInfo.findIndex(i => i.label === data.label)
        if (infoItemIndex >= 0) {
            plant.bottomInfo[infoItemIndex].value = data.value
            plant.bottomInfo[infoItemIndex].unit_of_measure = data.unit_of_measure
        } else {
            delete data.plant
            plant.bottomInfo.push(data)
        }
    },
    setPlantBottomInfoFromREST (state, data) {
        // differently from setPlantBottomInfo above,
        // which gets the data from the websocket,
        // this one gets the data from the REST api endpoint
        // which returns a different structure
        const plantId = data.plant
        const plantIndex = state.plants.findIndex(i => i.id === plantId)
        if (plantIndex === -1)
            return
        
        const datum = data.data[0]
        if (!datum) return

        const plant: Plant = state.plants[plantIndex]

        if (!plant.bottomInfo) plant.bottomInfo = []
        const infoItemIndex = plant.bottomInfo.findIndex(i => i.label === datum.label)
        if (infoItemIndex >= 0) {
            plant.bottomInfo[infoItemIndex].value = datum.value
            plant.bottomInfo[infoItemIndex].unit_of_measure = datum.unit_of_measure
        } else {
            plant.bottomInfo.push(datum)
        }
    },
    setPlantsData (state, plantsData: OndaApi.Paths.GetPlantData.Responses.$200) {
        state.plantsData = plantsData
    },
    setDataOnPlants (state, plantsData: OndaApi.Paths.GetPlantData.Responses.$200) {
        /**
         * Set plant's data on the data property on every plant object
         */
        for (let item of plantsData) {
            const plantId = item.plant
            const plantIndex = state.plants.findIndex(i => i.id === plantId)

            // check if there's a plant with the corresponding id in state.plants
            // if there's not, skip the current item
            if (plantIndex === -1) {
                continue
            }

            const plant: Plant = state.plants[plantIndex]

            // // Init data object, events and datasets if not done already
            // if (plant.data) {
            //     if (!plant.data.events) plant.data.events = []
            //     if (!plant.data.datasets) plant.data.datasets = []
            // }
            // else {
            //     plant.data = { events: [], datasets: [] }
            // }

            // // Push new values
            // plant.data.events.push(...item.events)
            // plant.data.datasets.push(...item.datasets)
            plant.data.events = item.events
            plant.data.datasets = item.datasets
            plant.status = item.status
        }
    },
    pushPlantData (state, data: newPlantDataFromWebSocket, debug=false) {
        if (debug) {
            console.log('pushPlantData has been triggered with the following data', data)
        }
        /**
         * Push incoming plant data from websocket to the right plant,
         * in the plant's dataset with the same label (if there's already, otherwise
         * to a new dataset)
         */

        // Search for the given plant - return if none is found
        const datumIdx = state.plantsData.findIndex(i => i.plant === data.plant)
        if (datumIdx < 0) return
        const stateDatum = state.plantsData[datumIdx]

        // See if the stateDatum already has a dataset with the given label
        // and push data accordingly
        const datasetIdx = stateDatum.datasets.findIndex(i => i.code === data.label)
        if (datasetIdx < 0) {
            // * Does not have dataset yet *
            stateDatum.datasets.push({
                code: data.label,
                label: data.label,
                dataset: [{
                    datetime: data.datetime,
                    value: data.value
                }]
            })
        } else {
            // * Has dataset already *
            /**
             * NB: in case the new data item (e.g. coming from the websocket)
             * refers to an older point in time, we can't just push it at the
             * end of the dataset, otherwise the chart interprets it as the
             * following point to link with its line, resulting in a weird line
             * that connetcts the point back and forth.
             * 
             * In order to avoid that, we only add the new data item if it
             * refers to a point in time more recent than the last data item
             * currently in the dataset. (We just ignore the data item instead
             * of searching the right point to insert in the dataset, bc we
             * basically don't need that...)
             */
            try {
                const lastItem = stateDatum.datasets[datasetIdx].dataset.slice(-1)[0]
                if (!lastItem || new Date(data.datetime) > new Date(lastItem.datetime)) {
                    stateDatum.datasets[datasetIdx].dataset.push({
                        datetime: data.datetime,
                        value: data.value
                    })
                }
            } catch (err) {
                console.error(err)
            }
        }
    },
    setWeathers (state, weathers) {
        /**
         * Set plant's weather property on every plant object
         */
        for (let item of weathers) {
            const plantId = item.plant_id
            const plantIndex = state.plants.findIndex(i => i.id === plantId)

            // check if there's a plant with the corresponding id in state.plants
            // if there's not, skip the current item
            if (plantIndex === -1) {
                continue
            }

            const plant: Plant = state.plants[plantIndex]

            const weather = {
                id: item.openweathermap_data.weather[0].id,
                temp: item.openweathermap_data.main.temp
            }
            Vue.set(plant, 'weather', weather)
        }
    },
    cleanIndexState (state) {
        /**
         * Reset all the elements in the state
         * to their initial values
         */
        state.pageTitle = ''
        state.plants = []
        state.plantsData = []
    }
}

export const actions: ActionTree<RootState, RootState> = {
    async getPlants (context) {
        const url = apiUrl({ endpoint: 'getPlants' })
        try {
            const plants: OndaApi.Paths.GetPlantsList.Responses.$200 = await this.$axios.$get(url)
            context.commit('setPlants', plants)
            context.commit('setDataOnPlants', context.state.plantsData)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantsData (context, params) {
        const url = apiUrl({ endpoint: 'getPlantsData', params: params || {} })
        try {
            const plantsData: OndaApi.Paths.GetPlantData.Responses.$200 = await this.$axios.$get(url)
            context.commit('setPlantsData', plantsData)
            context.commit('setDataOnPlants', plantsData)
        } catch (err) {
            console.error(err)
        }
    },
    async getWeathers (context) {
        const url = apiUrl({ endpoint: 'getWeathers', params: {} })
        try {
            const weathers = await this.$axios.$get(url)
            context.commit('setWeathers', weathers)
        } catch (err) {
            console.error(err)
        }
    },
    async getPlantsBottomInfo ({ commit }) {
        const url = apiUrl({ endpoint: 'getBottomInfo', params: {} })
        try {
            const plantsBottomInfo = await this.$axios.$get(url)
            for (let plant of plantsBottomInfo) {
                commit('setPlantBottomInfoFromREST', plant)
            }
        } catch (err) {
            console.error(err)
        }
    }
}
