export default {
    mounted () {
        this.setIsFullScreenState()
    },
    data () {
        return {
            isFullScreen: false
        }
    },
    methods: {
        async openFullscreen () {
            const el = document.documentElement
            if (el.requestFullscreen) {
                await el.requestFullscreen()
            } else if (el.mozRequestFullScreen) { /* Firefox */
                await el.mozRequestFullScreen()
            } else if (el.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                await el.webkitRequestFullscreen()
            } else if (el.msRequestFullscreen) { /* IE/Edge */
                await el.msRequestFullscreen()
            }
            this.setIsFullScreenState()
        },
        async closeFullscreen () {
            if (document.exitFullscreen) {
                await document.exitFullscreen()
            } else if (document.mozCancelFullScreen) { /* Firefox */
                await document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                await document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) { /* IE/Edge */
                await document.msExitFullscreen()
            }
            this.setIsFullScreenState()
        },
        setIsFullScreenState () {
            if (!document.fullscreenElement)
                this.isFullScreen = false
            else
                this.isFullScreen = true
        }
    }
}
