<template>
    <b-button
        type="is-light"
        icon-left="play"
        @click="startPresentation"
        class="presentation-button">
        Presentation
    </b-button>
</template>

<script>
import fullscreen from '~/mixins/fullscreen'

export default {
    mixins: [fullscreen],
    methods: {
        async startPresentation () {
            await this.openFullscreen()
                .catch(err => console.error(err))
            this.$router.push('/presentation')
        }
    }    
}
</script>
