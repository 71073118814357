import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

type inputData = Array<{
    /** Data coming from API */
    plant: {
        id: number,
        description: string,
        plant_type: string
    },
    revenues_currency: string,
    energies_metric: string,
    revenues: Array<{
        date: string,
        production: {
            net: number,
            gross: number
        },
        incentive: {
            energy: number,
            revenue: number
        },
        sale: {
            energy: number,
            revenue: number
        }
    }>
}>

export const state = () => ({
    revenues: [] as inputData,
    suggestedRevenues: [] as inputData,
})

export type RevenuesState = ReturnType<typeof state>

export const getters: GetterTree<RevenuesState, RootState> = {
    revenuesTotalAmount (state) {
        return state.revenues.reduce((total, revenueObj) => {
            return total + revenueObj.revenues.reduce((partial, currentRev) => {
                return partial + currentRev.incentive.revenue + currentRev.sale.revenue
            }, 0)
        }, 0)
    },
    productionTotalValue (state) {
        return state.revenues.reduce((total, revenuesObj) => {
            return total + revenuesObj.revenues.reduce((partial, currentRev) => {
                return partial + currentRev.production.gross
            }, 0)
        }, 0)
    }
}

export const mutations: MutationTree<RevenuesState> = {
    setRevenues (state, revenues) {
        state.revenues = revenues
    },
    setSuggestedRevenues (state, suggestedRevenues) {
        state.suggestedRevenues = suggestedRevenues
    }
}

export const actions: ActionTree<RevenuesState, RootState> = {
    async getRevenues (context, params) {
        const url = apiUrl({ endpoint: 'getRevenues', params })
        try {
            const revenues = await this.$axios.$get(url)
            context.commit('setRevenues', revenues)
            // console.log('revenues', revenues)
        } catch (err) {
            console.error(err)
        }
    },
    async getSuggestedRevenues (context, params) {
        const url = apiUrl({ endpoint: 'getSuggestedRevenues', params })
        try {
            const suggestedRevenues = await this.$axios.$get(url)
            context.commit('setSuggestedRevenues', suggestedRevenues)
            // console.log('revenues', revenues)
        } catch (err) {
            console.error(err)
        }
    }
}
