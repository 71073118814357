/**
 * 
 * Utilities to deal with the Onda's WebSocket API
 * 
 * NB: these are not generic helpers/utils - are just tailored
 * for the Onda's WebSocket API
 *  
 */

const isObject = (val: any) => typeof(val) === 'object' && !Array.isArray(val)

const subUnsubMixin = (action: 'subscribe' | 'unsubscribe', socket: WebSocket, identifiers: String | Object | String[] | Object[]) => {

    const waitForOpenConnection = () => { // PUBBLICARE GIST
        return new Promise((resolve, reject) => {
            const maxNumberOfAttempts = 10
            const intervalTime = 200 //ms

            let currentAttempt = 0
            const interval = setInterval(() => {
                if (currentAttempt > maxNumberOfAttempts - 1) {
                    clearInterval(interval)
                    reject(new Error('Maximum number of attempts exceeded'))
                } else if (socket.readyState === socket.OPEN) {
                    clearInterval(interval)
                    resolve()
                }
                currentAttempt++
            }, intervalTime)
        })
    }

    const sendMessage = async (identifier: String) => {
        const msg = JSON.stringify({ "command": action, identifier })
        if (socket.readyState !== socket.OPEN) {
            try {
                await waitForOpenConnection()
                socket.send(msg)
            } catch (err) { console.error(err) }
        } else {
            socket.send(msg)
        }
    }

    /**
     * Handle the different possible types for identifiers
     */
    if (typeof identifiers === 'string') {  // String
        sendMessage(`{"channel":"${identifiers}"}`)
    }
    else if(isObject(identifiers)) {  // Object
        sendMessage(JSON.stringify(identifiers))
    }
    else if (Array.isArray(identifiers)) {
        for (let identifier of identifiers) {
            if (typeof identifier === 'string')  // String[]
                sendMessage(`{"channel":"${identifier}"}`)
            else if (isObject(identifier))
                sendMessage(JSON.stringify(identifier))  // Object[]
        }
    }
}

export const subscribe = (socket: WebSocket, identifiers: String | Object | String[] | Object[]) => {
    /**
     * Subscribe to a given channell over a given WebSocket open connection
     */
    subUnsubMixin('subscribe', socket, identifiers)
}

export const unsubscribe = (socket: WebSocket, identifiers: String | Object | String[] | Object[]) => {
    /**
     * Unsubscribe to a given channell over a given WebSocket open connection
     */
    subUnsubMixin('unsubscribe', socket, identifiers)
}

interface MsgHandler { channel: String, callback: Function }
export const setMsgHandlers = (socket: WebSocket, msgHandlers: MsgHandler[]) => {
    /**
     * Given the open socket's connection, set a function to handle
     * any incoming message belongin to a given channel.
     */
    socket.onmessage = ev => {
        const data = JSON.parse(ev.data)
        if (!data.identifier)
            return
        const incomingMsgChannel = JSON.parse(data.identifier).channel

        const handlerIdx = msgHandlers.findIndex(i => i.channel === incomingMsgChannel)
        const handler = msgHandlers[handlerIdx]

        if (handler && data.message) {
            handler.callback(data.message)
        }
    }
}
