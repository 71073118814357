<template>
    <div class="nuxt-error">
        <component :is="errorPage" :error="error" />
    </div>
</template>

<script>
import generic from './errors/generic'
import cannotLoadUser from './errors/cannotLoadUser'

export default {
    layout: 'footer_only', // optional
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        errorPage() {
            if (this.error.statusCode === 'loading-user') {
                return cannotLoadUser
            }
            // catch everything else
            return generic
        }
    }
}
</script>

<style scoped>
.nuxt-error {
    padding: 1rem;
    text-align: center;
}
</style>
