import { GetterTree, ActionTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface phoneNumber {
    id: number,
    number: string,
    local_number: string,
    phone_type: 'mobile' | 'office' | 'home' | 'other'
}
interface workerCompany {
    id: number,
    address: string,
    name: string,
    longitude: number,
    latitude: number,
}
interface userFromApi {
    id: number,
    username: string,
    name: string,
    surname: string,
    role: string,
    email: string | null,
    annotation: string | null,
    phone_numbers: phoneNumber[],
    plant_ids?: number[],
    can_check_plants: boolean,
    worker_company?: workerCompany,
}

export const state = () => ({
    users: [] as userFromApi[],
})

export type UsersState = ReturnType<typeof state>

export const getters: GetterTree<UsersState, RootState> = {
    workers (state) {
        return state.users.filter(u => u.role === 'worker')
    },
    usersAllowedToCheckPlants(state){
        return state.users.filter(u => u.can_check_plants === true)
    }
}

export const mutations: MutationTree<UsersState> = {
    setUsers (state, users) {
        state.users = users
    },
    delUser (state, userId) {
        const idx = state.users.findIndex((m) => m.id == userId)
        if (idx > -1){
            state.users.splice(idx, 1);
        }
    },
    addOrReplaceUser (state, user) {
        state.users = [
            ...state.users.filter(element => element.id !== user.id),
            user
        ]
    }
}

export const actions: ActionTree<UsersState, RootState> = {
    async getUsers (context) {
        const url = apiUrl({ endpoint: 'getUsers', params: {} })
        try {
            const users = await this.$axios.$get(url)
            context.commit('setUsers', users)
        } catch (err) {
            console.error(err)
        }
    },
    async getUser (context, userId) {
        const url = apiUrl({ endpoint: 'getUser', params: { userId } })
        try {
            const user = await this.$axios.$get(url)
            context.commit('addOrReplaceUser', user)
        } catch (err) {
            console.error(err)
        }
    },
    async delUser (context, userId) {
        const url = apiUrl({ endpoint: 'getUser', params: { userId } })
        try {
            await this.$axios.$delete(url)
            context.commit('delUser', userId)
        } catch (err) {
            console.error(err)
        }
    },
    async createUser (context, newUser) {
        const url = apiUrl({ endpoint: 'getUsers', params: {} })
        try {
            const user = await this.$axios.$post(url, { user: newUser })
            context.commit('addOrReplaceUser', user )
        } catch (err) {
            console.error(err)
        }
    },
    async updateUser (context, {userId, editedUser}) {
        const url = apiUrl({ endpoint: 'getUser', params: { userId } })
        try {
            const user = await this.$axios.$put(url, { user: editedUser })
            context.commit('addOrReplaceUser', user )
        } catch (err) {
            console.error(err)
        }
    }
}
