<template>
    <section>
        <template>
            <navbar class="navbar" />
            <mobile-navbar class="mobile-navbar" />
        </template>

        <nuxt class="main-content" />

        <my-footer class="my-footer" />
    </section>
</template>

<script>
import Navbar from '~/components/layout/Navbar'
import MobileNavbar from '~/components/layout/MobileNavbar'
import MyFooter from '~/components/layout/Footer'

export default {
    components: {
        Navbar,
        MobileNavbar,
        MyFooter
    }
}
</script>

<style scoped>
/* Small screens */
@media screen and (max-width: 768px) {
    .navbar {
        display: none;
    }
}
/* Medium screens + */
@media screen and (min-width: 769px) {
    .mobile-navbar {
        display: none;
    }
}

.main-content {
    padding-bottom: 70px;
}
</style>

<style>
.my-footer {
    padding: .5rem;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    max-height: 60px;
    overflow-y: scroll;
}
</style>
