import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface maintenanceReportFromApi {
    id: number,
    plant_id: number,
    date: string,
    description: string,
    user_id: string,
    duration?: number,
    people_count?: number,
    cause?: string,
    solves_problem?: boolean,
    maintenance_id?: number,
    photo?: {
        url: string,
        thumb_url: string
    }
}

export const state = () => ({
    reports: [] as maintenanceReportFromApi[],
})

export type MaintenanceReportsState = ReturnType<typeof state>

export const getters: GetterTree<MaintenanceReportsState, RootState> = {
    reportById (state) {
        return (reportId: number) => state.reports.find(m => m.id == reportId)
    },
}

export const mutations: MutationTree<MaintenanceReportsState> = {
    setReports (state, reports) {
        state.reports = reports
    },
    addOrReplaceReport (state, report) {
        state.reports = [
            ...state.reports.filter(element => element.id !== report.id),
            report
        ]
    },
    delReport (state, reportId) {
        const idx = state.reports.findIndex((m) => m.id == reportId)
        if (idx > -1){
            state.reports.splice(idx, 1);
        }
    }
}

export const actions: ActionTree<MaintenanceReportsState, RootState> = {
    async getReports (context, page=null) {
        // TODO verificare size con parte backend (sembra che adesso
        // mandi sempre size: 50)
        const params = page ? { page, size: 50 } : {}
        const url = apiUrl({ endpoint: 'getMaintenanceReports', params })
        try {
            const reports = await this.$axios.$get(url)
            context.commit('setReports', reports)
        } catch (err) {
            console.error(err)
        }
    },
    async getReport (context, reportId) {
        const url = apiUrl({ endpoint: 'getMaintenanceReport', params: { reportId } })
        try {
            const report = await this.$axios.$get(url)
            context.commit('addOrReplaceReport', report)
        } catch (err) {
            console.error(err)
        }
    },
    async delReport (context, reportId) {
        const url = apiUrl({ endpoint: 'getMaintenanceReport', params: { reportId } })
        try {
            await this.$axios.$delete(url)
            context.commit('delReport', reportId)
        } catch (err) {
            console.error(err)
        }
    },
    async createReport (context, report) {
        const url = apiUrl({ endpoint: 'getMaintenanceReports', params: {} })
        try {
            await this.$axios.$post(url, { maintenance_report: report })
            context.dispatch('getReports')
        } catch (err) {
            console.error(err)
        }
    },
    async updateReport (context, {reportId, report}) {
        const url = apiUrl({ endpoint: 'getMaintenanceReport', params: { reportId } })
        try {
            await this.$axios.$put(url, { maintenance_report: report })
            context.dispatch('getReports')
        } catch (err) {
            console.error(err)
        }
    },
    async exportReports (context, params) {
        const url = apiUrl({ endpoint: 'getReportsCSV', params })
        try {
            const csv = await this.$axios.$get(url, { responseType: 'blob' })
            const blob = new Blob([csv], { type:'text/csv'})
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            if (params.month) {
                link.download = `reports-${params.year}-${params.month}.csv`
            } else {
                link.download = `reports-${params.year}.csv`
            }
            link.click()
        } catch (err) {
            console.error(err)
        }
    }
}
