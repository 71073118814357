<template>
    <nav>
        <div class="nav-group start">
            <nuxt-link
                :to="{ path: '/'}"
                class="logo-link">
                <img v-if="isEndUser" class="logo" src="~/assets/logo-end-user.png" alt="Onda logo">
                <img v-else class="logo" src="~/assets/logo.svg" alt="Onda logo">
            </nuxt-link>

            <b-dropdown hoverable aria-role="menu" v-if="!isWorker">
                <button class="button is-light" slot="trigger">
                    <span>Menu</span>
                    <b-icon icon="menu-down" size="is-small" />
                </button>

                <b-dropdown-item custom aria-role="menuitem">
                    <span class="menu-section-title">Ticketing</span>
                </b-dropdown-item>
                <b-dropdown-item
                    value="requests"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/maintenances/requests'}">
                        <b-icon icon="screwdriver" size="is-small"></b-icon>
                        <span>{{ $t('maintenance_requests') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <b-dropdown-item
                    value="reports"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/maintenances/reports'}">
                        <b-icon icon="file-chart" size="is-small"></b-icon>
                        <span>{{ $t('maintenance_reports') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <b-dropdown-item
                    value="requests"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/downtimes'}">
                        <b-icon icon="trending-down" size="is-small"></b-icon>
                        <span>{{ $t('downtimes') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <b-dropdown-item
                    value="events"
                    aria-role="menuitem"
                    :focusable="false"  v-if="!isWorker && !isEndUser"
                    has-link>
                    <nuxt-link :to="{ path: '/events/'}">
                        <b-icon icon="message" size="is-small"></b-icon>
                        <span>Events</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                    value="plantsMonitoring"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link
                        class="navbar-item"
                        :to="{path: '/plantsmonitoring'}">
                        <b-icon icon="chart-bar" size="is-small"></b-icon>
                        <span>Plants Monitoring</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                    value="rgis"
                    aria-role="menuitem"
                    :focusable="false" v-if="!isWorker && !isEndUser"
                    has-link>
                    <nuxt-link
                        class="navbar-item"
                        :to="{ path: '/documents'}">
                        <b-icon icon="file-document" size="is-small"></b-icon>
                        <span>{{ $t('documents') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                    value="rgis"
                    aria-role="menuitem"
                    :focusable="false" v-if="!isWorker && !isEndUser"
                    has-link>
                    <nuxt-link
                        class="navbar-item"
                        :to="{ path: '/rgis'}">
                        <b-icon icon="calendar" size="is-small"></b-icon>
                        <span>{{ $t('rgi_management') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item
                    value="revenues"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link
                        class="navbar-item"
                        :to="{ path: '/revenues'}">
                        <b-icon icon="finance" size="is-small"></b-icon>
                        <span>{{ $t('revenues') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider">

                <b-dropdown-item custom aria-role="menuitem">
                    <span class="menu-section-title">Tools</span>
                </b-dropdown-item>
                <b-dropdown-item
                    value="k451"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/k451'}">
                        <b-icon icon="file-chart" size="is-small"></b-icon>
                        <span>{{ $t('k451') }}</span>
                    </nuxt-link>
                </b-dropdown-item>
                <b-dropdown-item
                    value="k410"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/k410'}">
                        <b-icon icon="file-chart" size="is-small"></b-icon>
                        <span>{{ $t('k410') }}</span>
                    </nuxt-link>
                </b-dropdown-item>
                <b-dropdown-item
                    value="k404"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/k404'}">
                        <b-icon icon="file-chart" size="is-small"></b-icon>
                        <span>{{ $t('k404') }}</span>
                    </nuxt-link>
                </b-dropdown-item>
                <b-dropdown-item
                    value="k405"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/k405'}">
                        <b-icon icon="file-chart" size="is-small"></b-icon>
                        <span>{{ $t('k405') }}</span>
                    </nuxt-link>
                </b-dropdown-item>
                <b-dropdown-item
                    value="Log Curve"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/tools/curves-logs'}">
                        <b-icon icon="post-outline" size="is-small"></b-icon>
                        <span>{{ $t('Log Curve') }}</span>
                    </nuxt-link>
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <div class="nav-group middle has-text-centered">
            <h1 v-html="pageTitle">{{ pageTitle }}</h1>
        </div>

        <div class="nav-group end">
            <presentation-button v-if="isEndUser" />

            <notification-button />

            <b-dropdown
                hoverable
                position="is-bottom-left"
                aria-role="menu">
                <a
                    class="navbar-item"
                    slot="trigger"
                    role="button">
                    <b-icon icon="account" size="is-small" />
                    <span v-if="user" style="margin: 0 .3rem;">{{ user.username }}</span>
                    <span v-else style="margin: 0 .3rem;">user</span>
                    <b-icon icon="menu-down" size="is-small" />
                </a>

                <b-dropdown-item
                    value="users"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/settings/'}">
                        <b-icon icon="settings" size="is-small"></b-icon>
                        <span>{{ $t('settings') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <b-dropdown-item v-if="!isWorker && !isEndUser"
                    value="users"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/users/'}">
                        <b-icon icon="account" size="is-small"></b-icon>
                        <span>{{ $t('users_management') }}</span>
                    </nuxt-link>
                </b-dropdown-item>

                <b-dropdown-item v-if="!isWorker && !isEndUser"
                    value="plants"
                    aria-role="menuitem"
                    :focusable="false"
                    has-link>
                    <nuxt-link :to="{ path: '/plants/'}">
                        <b-icon icon="office-building" size="is-small"></b-icon>
                        <span>Plants management</span>
                    </nuxt-link>
                </b-dropdown-item>

                <hr class="dropdown-divider" v-if="!isWorker && !isEndUser">

                <b-dropdown-item
                    value="logout"
                    aria-role="menuitem"
                    :focusable="false"
                    @click="logout">
                    <b-icon icon="logout" size="is-small"></b-icon>
                    <span>Logout</span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PresentationButton from './PresentationButton'
import NotificationButton from './NotificationsButton'

export default {
    components: {
        PresentationButton,
        NotificationButton
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', [
            'isEndUser',
            'isWorker'
        ]),
        pageTitle() {
            return this.$store.state.pageTitle;
        },
        isLoggedIn () {
            return this.$store.getters['auth/loggedIn']
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>

<style>
.presentation-button {
    display: none;
}
@media screen and (min-width: 769px) {
    .presentation-button {
        display: unset;
    }
}
</style>

<style scoped>
nav > * {
    margin: 0;
    padding: 0;
}

nav {
    background: #fff;
    display: flex;
    padding: .5rem .8rem;
}

.nav-group {
    flex-grow: 1;
    /* border: 1px solid #eee; */
    display: flex;
    align-items: center;
}
.nav-group.middle {
    flex-grow: 2;
    justify-content: center;
}
.nav-group.end {
    justify-content: flex-end;
}
.nav-group > *:not(:last-child) {
    margin-right: .75rem;
}

.logo-link, .logo {
    height: 50px;
}

h1 {
    font-weight: bolder;
    font-size: 2rem;
}

.menu-section-title {
    font-size: .9rem;
    opacity: .5;
    font-weight: 600;
}
</style>
