import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex'
import { ToastProgrammatic as Toast, NotificationProgrammatic as Notification } from 'buefy'

import { RootState } from '~/store'

export enum ErrorCode {
    Generic,
    Axios401 = 401,
    AxiosGeneric = 'axios-generic'
}

export type InputError = {
    code?: ErrorCode,
    message?: string
}

export type Error = {
    code: ErrorCode,
    message: string,
    notified: boolean
}

export const state = () => ({
    errors: [] as Array<Error>
})

export type ErrorsState = ReturnType<typeof state>

export const getters: GetterTree<ErrorsState, RootState> = {
    axios401Errors (state) {
        return state.errors.filter(e => e.code && e.code === ErrorCode.Axios401)
    },
    axiosGenericErrors (state) {
        return state.errors.filter(e => e.code && e.code === ErrorCode.AxiosGeneric)
    }
}

export const mutations: MutationTree<ErrorsState> = {
    ADD_ERROR (state, error: Error) {
        state.errors.push(error)
    },
    REMOVE_ERROR_BY_INDEX (state, index: number) {
        state.errors.splice(index, 1)
    },
    REMOVE_ERRORS_BY_CODE (state, code: ErrorCode) {
        const lastItemIdx = state.errors.length - 1
        for (let i = lastItemIdx; i > -1; i--) {
            const e = state.errors[i]
            if (e.code === code) {
                state.errors.splice(i, 1)
            }
        }
    },
    MARK_ERROS_NOTIFIED_BY_CODE (state, code: ErrorCode) {
        for (let e of state.errors) {
            if (e.code === code)
                e.notified = true
        }
    }
}

function showError401 (context: ActionContext<ErrorsState, RootState>) {
    let message
    if (context.rootGetters['auth/tokenHasExpired']) {
        context.dispatch('auth/logout', undefined, { root: true })
        message = 'Your authentication token has expired'
    } else {
        message = 'Unauthorized request'
    }
    Toast.open({
        message: message,
        type: 'is-danger'
    })
}

export const actions: ActionTree<ErrorsState, RootState> = {
    addError (context, inputError: InputError) {
        /**
         * NOTE:
         * another way to do it would be to set a timestamp on the error
         * instead of insert/delete an error every time
         * (then you just check the timestamto of the previous error w/ same code)
         */

        let error: Error = {
            code: ErrorCode.Generic,
            message: inputError.message || '',
            notified: false
        }
        if (inputError.code) {
            error.code = inputError.code
        }

        if (error.code === ErrorCode.Axios401) {
            if (context.getters['axios401Errors'].length === 0) {
                context.commit('ADD_ERROR', error)
            }

            if (context.getters['axios401Errors'].length > 0) {
                const e = context.getters['axios401Errors'][0]
                if (!e.notified) {
                    showError401(context)
                    context.commit('MARK_ERROS_NOTIFIED_BY_CODE', e.code)
                }
                setTimeout(() => context.commit('REMOVE_ERRORS_BY_CODE', error.code), 2000)
            }
        } else if (error.code === ErrorCode.AxiosGeneric) {
            if (context.getters['axiosGenericErrors'].length === 0) {
                context.commit('ADD_ERROR', error)
            }

            if (context.getters['axiosGenericErrors'].length > 0) {
                const e = context.getters['axiosGenericErrors'][0]
                if (!e.notified) {
                    Notification.open({
                        duration: 7500,
                        message: `<b>Network request has failed</b> (open console for more info).`,
                        position: 'is-top',
                        type: 'is-danger',
                        hasIcon: false
                    })
                    context.commit('MARK_ERROS_NOTIFIED_BY_CODE', e.code)
                }
                setTimeout(() => context.commit('REMOVE_ERRORS_BY_CODE', error.code), 7500)
            }
        } else {
            Toast.open({
                message: 'An Error has occoured. More info in console.',
                type: 'is-danger'
            })
        }
    }
}
