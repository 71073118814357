import { extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('required', {
    ...required,
    message: '{_field_} is required'
})

extend('min', {
    ...min,
    message: '{_field_} must be at least 8 characters long'
})