<template>
    <div class="main">
        <b-button @click="goToNotifications">
            <b-icon icon="bell" size="is-small" />
        </b-button>

        <div v-if="newNotifications.length > 0" class="red-dot" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { subscribe, unsubscribe } from '~/utils/ws'

export default {
    created () {
        subscribe(this.ws, 'notifications')
    },
    beforeDestroy () {
        unsubscribe(this.ws, 'notifications')
    },
    computed: {
        ...mapState('webSocket', ['ws']),
        ...mapState('notifications', ['newNotifications'])
    },
    methods: {
        ...mapMutations('notifications', ['emptyNotifications']),
        goToNotifications () {
            this.emptyNotifications()
            this.$router.push({ path: '/notifications' })
        }
    }
}
</script>

<style scoped>
.main {
    position: relative;
}

.red-dot {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 9999px;
}
</style>
