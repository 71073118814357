const baseUrl = process.env.baseUrl

export default baseUrl

function setQueryParams (url, params) {
    /**
     * url: string
     * params: Object
     */

    let index = 0  /** NB this is not the index of the current loop's iteration.
     * It's the "index of the position" of the param in the url.
     * That's why we don't use Object.keys(params).indexOf(key) anymore
     * (because with that, if the first param is undefined or null,
     * the loop continue, but the index gets incremented nonetheless)
     */
    for (let key in params) {
        if (!params[key]) continue
        // const index = Object.keys(params).indexOf(key)
        const prefix = index === 0 ? '?' : '&'
        url += `${prefix}${key}=${params[key]}`
        index++
    }
    return url
}

const endpoints = {
    getToken: '/auth/token/',
    getUser: ({ userId }) => `/users/${userId}/`,
    getLoggedUser: '/logged-user/',
    userPreferences: '/preferences/',
    userPreference: ({ code }) => `/preferences/${code}/`,
    getUsers: ({ plantId, role }) => {
        return setQueryParams('/users/', {
            plant_id: plantId,
            role
        })
    },
    getPlants: '/plants/',
    getPlantDetail: ({ plantId }) => `/plants/${plantId}/`,
    getPlantTypes: '/plant-types/',
    getPlantsData: ({ plantId, begin, end, codes }) => {
        return setQueryParams('/plants-data/', {
            plant_id: plantId,
            codes,
            begin,
            end
        })
    },
    getTablePlantsData: ({ begin, end, deviceType }) => {
        return setQueryParams('/plants-monitoring/', {
            device_type: deviceType,
            begin,
            end
        })
    },
    getTablePlantData: ({ plantId, begin, end, deviceType }) => {
        return setQueryParams('/plants-monitoring/', {
            plant_id: plantId,
            device_type: deviceType,
            begin,
            end
        })
    },
    getDevices: ({ plantId }) => `/plants/${plantId}/devices/`,
    getDeviceDetail: ({ plantId, deviceName }) => `/plants/${plantId}/devices/${deviceName}/`,
    sendDeviceCommand: ({ plantId, deviceName }) => `/plants/${plantId}/devices/${deviceName}/commands/`,
    getWeathers: ({ plantId }) => {
        return setQueryParams('/weathers.json', {
            plant_id: plantId
        })
    },
    getBottomInfo: ({ plantId }) => {
        return setQueryParams('/plants-bottom-info/', {
            plant_id: plantId
        })
    },
    getPresentationInfoSummary: '/presentation-summary/',
    getPresentationData: ({ plantId, begin, end }) => {
        return setQueryParams('/presentation/', {
            plant_id: plantId,
            begin,
            end
        })
    },
    getMaintenanceRequests: ({ savedForLater, plantId, assignedTo, page, size }) => {
        return setQueryParams(`/maintenances/`, {
            saved_for_later: savedForLater,
            plant_id: plantId,
            assigned_to: assignedTo,
            page,
            size
        })
    },
    getMaintenanceTypes: '/maintenance-types/',
    getMaintenanceRequest: ({ requestId }) => `/maintenances/${requestId}/`,
    getMaintenanceReports: ({ month, year, page, size }) => {
        return setQueryParams(`/maintenance-reports/`, {
            month,
            year,
            page,
            size
        })
    },
    getMaintenanceReport: ({ reportId }) => `/maintenance-reports/${reportId}/`,
    getPlantsWorkersList: '/users/?role=worker',
    getReportsCSV: ({ month, year, plantId, userId}) => {
        return setQueryParams('/maintenance-reports.csv/', {
            month,
            year,
            plant_id: plantId,
            user_id: userId
        })
    },
    getEvents: ({ beginAt, endAt, plant, type, device, priority }) => {
        return setQueryParams(`/events/`, {
            begin_at: beginAt,
            end_at: endAt,
            plant,
            type,
            device,
            priority
        })
    },
    getEvent: ({ eventId }) => `/events/${eventId}/`,
    getRevenues: ({ plantId, begin, end, interval }) => {
        return setQueryParams('/revenues/', {
            plant_id: plantId,
            begin,
            end,
            interval
        })
    },
    getSuggestedRevenues: ({ plantId, begin, end, kwh }) => {
        return setQueryParams('/suggested-revenues/', {
            plant_id: plantId,
            begin,
            end,
            kwh
        })
    },
    getRgis: '/rgis/',
    getRgiDetail: ({ rgiId }) => `/rgis/${rgiId}/`,
    getRgisXLS: ({begin, end, userId}) => {
        return setQueryParams('/rgis.csv/', {
            begin,
            end,
            user_id: userId
        })
    },
    getDowntimes: ({ from, to, plant_id }) => {
        return setQueryParams(`/downtimes/`, {
            from,
            to,
            plant_id
        })
    },
    addDowntime: ({ params }) => {
        return setQueryParams(`/downtimes/`, {
            downtime: params
        })
    },
    getDowntime: ({ downtimeId }) => `/downtimes/${downtimeId}/`,
    getDowntimeCauses: '/downtime-causes/',
    getSuggestDowntimes: ({ from, to }) => {
        return setQueryParams(`/suggested-downtimes/`, {
            from,
            to
        })
    },
    getSuggestLost: ({ from, to, plant_id, suggested_lost }) => {
        return setQueryParams(`/suggested-downtimes/`, {
            from,
            to,
            plant_id,
            suggested_lost
        })
    },
    getPlantsDowntime: ({ from, to, plant_id }) => {
        return setQueryParams(`/plants-downtimes/`, {
            from,
            to,
            plant_id
        })
    },
    getReportsK451: ({ month, year}) => {
        return setQueryParams('/k451_xlsx.xlsx/', {
            month,
            year,
        })
    },
    notifications: '/notifications/',
    notification: ({ id }) => `/notifications/${id}/`,
    alarmsPreferences: '/alarm-preferences/',
    alarmPreference: ({ id }) => `/alarm-preferences/${id}/`,
    defaultAlarmsPreferences: '/default-alarm-preferences/',
    alarmsPreferencesOptions: '/alarm-preferences-options/',
    alarms: ({ plant_id }) => {
        return setQueryParams('/alarms/', {
            plant_id
        })
    },
    documents: ({ doc_type }) => {
        return setQueryParams('/documents/', {
            type: doc_type
        })
    },
    getEdistribuzioneData: ({ month, year }) => {
        return setQueryParams(`/data-edistribuzione/`, {
            month,
            year,
        })
    },
    getEdelwiseData: ({ month, year }) => {
        return setQueryParams(`/data-edelwise/`, {
            month,
            year,
        })
    },
    getGseTernaData: ({ month, year }) => {
        return setQueryParams(`/gse-terna/`, {
            month,
            year,
        })
    },
    getGseData: ({ month, year }) => {
        return setQueryParams(`/data-gse/`, {
            month,
            year,
        })
    },
    startJobEdistibuzione: ({ month, year, pod }) => {
        return setQueryParams(`/start-getting-edistribuzione-data/`, {
            month,
            year,
            pod,
            force: true,
            send_email: false
        })
    },
    getKxxxData: ({ year, tool }) => {
        return setQueryParams(`/get-kxxx-data/`, {
            year,
            tool
        })
    },

    injectCurveGross: ({ file, serial_number, plant }) => {
        return setQueryParams(`/inject-gross//`, {
            file: file,
            serial_number: serial_number,
            plant: plant
        })
    },

    getExcelK410: ({ year, email }) => {
        return setQueryParams(`/get-k410/`, {
            year: year,
            email: email
        })
    },
}

export const apiUrl = (settings) => {
    /**
     * settings structure:
     * {
     *     endpoint: 'endpointName',
     *     params: {
     *         companyId: 1,
     *         id: 2,
     *     }
     * }
     */
    const endpoint = endpoints[settings.endpoint]
    if ('params' in settings) {
        return baseUrl + endpoint(settings.params)
    }
    return baseUrl + endpoint
}
