<template>
    <footer :class="[type]">
        <span class="company">ELYTICS SOFTWARE S.r.l.</span>,
        <span class="vat-number">p.iva 02332180989</span>,
        <span class="address">Via Bariaga n. 8 – 25085 Gavardo (BS)</span>,
        <span class="group">
            <a href="https://www.energievalsabbia.it">
                Gruppo EVA Energie Valsabbia
            </a>
        </span>
    </footer>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'simple',
            validator (val) {
                return ['simple'].indexOf(val) > -1
            }
        }
    }
}
</script>

<style scoped>
footer {
    font-size: .65rem;
    opacity: .5;
    font-weight: 500;
}
</style>
