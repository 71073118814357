import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface curvesFromApi {
    id: number,
    plant_id: number,
    start: string,
    stop: string,
    description: string,
    energy_lost: number,
    money_lost: number,
    estimated_repair_cost: number,
    percentage_down: number,
    insurance_claim: boolean,
    created_by_id: number,
    downtime_id: number,
    created_at: string,
    updated_at: string,
    time_report: string,
    time_intervention: string,
    cause: string,
}

export const state = () => ({
    edistribuzione: [],
    gse: [],
    edelwise: [],
    gseTerna: [],
    k410Data: [],
    k404Data: [],
    k405Data: []
})

export type CurvesState = ReturnType<typeof state>

export const mutations: MutationTree<CurvesState> = {
    setEdistribuzioneData (state, edistribuzione) {
        state.edistribuzione = edistribuzione
    },
    setGseData (state, gse) {
        state.gse = gse
    },
    setEdelwiseData (state, edelwise) {
        state.edelwise = edelwise
    },
    setGseTernaData (state, gseTerna) {
        state.gseTerna = gseTerna
    },
    setK410Data (state, k410Data) {
        state.k410Data = k410Data
    },
    setK404Data (state, k404Data) {
        state.k404Data = k404Data
    },
    setK405Data (state, k405Data) {
        state.k405Data = k405Data
    },
}

export const actions: ActionTree<CurvesState, RootState> = {

    async getEdistribuzioneData (context, params) {
        const url = apiUrl({ endpoint: 'getEdistribuzioneData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setEdistribuzioneData', data)
        } catch (err) {
            console.error(err)
        }
    },

    async getGseData (context, params) {
        const url = apiUrl({ endpoint: 'getGseData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setGseData', data)
        } catch (err) {
            console.error(err)
        }
    },

    async getEdelwiseData (context, params) {
        const url = apiUrl({ endpoint: 'getEdelwiseData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setEdelwiseData', data)
        } catch (err) {
            console.error(err)
        }
    },

    async getGseTernaData (context, params) {
        const url = apiUrl({ endpoint: 'getGseTernaData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setGseTernaData', data)
        } catch (err) {
            console.error(err)
        }
    },

    async startJobEdistibuzione (context, params) {
        const url = apiUrl({ endpoint: 'startJobEdistibuzione', params: params })
        try {
            return await this.$axios.$get(url)
        } catch (err) {
            console.error(err)
        }
    },

    async getK410Data (context, params) {
        const url = apiUrl({ endpoint: 'getKxxxData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setK410Data', data)
        } catch (err) {
            console.error(err)
        }
    },

    async getK404Data (context, params) {
        const url = apiUrl({ endpoint: 'getKxxxData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setK404Data', data)
        } catch (err) {
            console.error(err)
        }
    },

    async getK405Data (context, params) {
        const url = apiUrl({ endpoint: 'getKxxxData', params: params })
        try {
            const data = await this.$axios.$get(url)
            context.commit('setK405Data', data)
        } catch (err) {
            console.error(err)
        }
    },


    async exportK410 (context, params) {
        const url = apiUrl({ endpoint: 'getExcelK410', params: params })
        try {
            const data = await this.$axios.$get(url)
        } catch (err) {
            console.error(err)
        }
    },


    async injectCurveGross (context, params) {
        const url = apiUrl({ endpoint: 'injectCurveGross', params: params })
        try {
            const res = await this.$axios.$post(url, params)
        } catch (err) {
            console.error(err)
        }
    },
}
