export default function ({ store, redirect, error }) {
    // If the user is not authenticated
    if (!store.getters['auth/loggedIn']) {
        return redirect('/login')
    }

    // If the user is logged in, but cannot get the account data
    if (!store.state.auth.user) {
        error({ statusCode: 'loading-user', message: '' })
    }

    store.dispatch('auth/setAuthHeader', store.state.auth.token)
}
