import { GetterTree, ActionTree, MutationTree } from 'vuex'
import * as moment from "moment"

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface rgiFromApi {
    id: number,
    rgi_id: number,
    lower_bound: string,
    upper_bound: string,
    nonworking_day: boolean,
}

export const state = () => ({
    rgis: [] as rgiFromApi[],
    clickedDay: null,
    start: null as moment.Moment | null,
    end: null as moment.Moment | null,
})

export type RgisState = ReturnType<typeof state>

export const mutations: MutationTree<RgisState> = {
    setRgis (state, rgis) {
        state.rgis = rgis
    },
    addOrReplaceRgi (state, rgi) {
        const index = state.rgis.indexOf(rgi);
        if(index > 0)
            state.rgis.splice(index, 0, rgi)
        else
              state.rgis.push(rgi)
    },
    setClickedDay (state, clickedDay) {
        state.clickedDay = clickedDay
    },
    setStartSelection (state, start) {
        state.start = start
    },
    setEndSelection (state, end) {
        state.end = end
    },
}

export const actions: ActionTree<RgisState, RootState> = {
    async cleanDays (context) {
        context.commit('setClickedDay', null);
        context.commit('setStartSelection', null);
        context.commit('setEndSelection', null)
    },
    async getRgis (context) {
        const url = apiUrl({ endpoint: 'getRgis' })
        try {
            const rgis: rgiFromApi[] = await this.$axios.$get(url)
            context.commit('setRgis', rgis)
        } catch (err) {
            console.error(err)
        }
    },
    async getRgi (context, rgiId) {
        const url = apiUrl({ endpoint: 'getRgiDetail', params: { rgiId } })
        try {
            const rgi: rgiFromApi = await this.$axios.$get(url)
            context.commit('addOrReplaceRgi', rgi)
        } catch (err) {
            console.error(err)
        }
    },
    async createRgi (context, newRgi) {
        const url = apiUrl({ endpoint: 'getRgis' })
        try {
            const rgi: rgiFromApi = await this.$axios.$post(url, { rgi: newRgi })
            context.commit('addOrReplaceRgi', rgi )
        } catch (err) {
            console.error(err)
        }
    },
    async updateRgi (context, {rgiId, editedRgi}) {
        const url = apiUrl({ endpoint: 'getRgiDetail', params: { rgiId } })
        try {
            const rgi: rgiFromApi = await this.$axios.$put(url, { rgi: editedRgi })
            context.commit('addOrReplaceRgi', rgi )
        } catch (err) {
            console.error(err)
        }
    },
    async getAddOrReplaceRgi (context) {
        const url = apiUrl({ endpoint: 'getRgis' })
        try {
            const rgis: rgiFromApi[] = await this.$axios.$get(url)
            context.commit('setRgis', rgis)
        } catch (err) {
            console.error(err)
        }
    },
}
