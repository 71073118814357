<template>
    <section>
        <p>Si è verificato un problema e non è possibile visualizzare la pagina.</p>
        <p
            v-if="error.message"
            style="padding: 1rem; font-weight: bold"
        >
            {{ error.message }}
        </p>
        <p>Prova a ricarivare la pagina. Se l'errore persiste, contatta l'assistenza di Onda.</p>
    </section>
</template>

<script>
export default {
    props: ['error']
}
</script>
