import Vue from 'vue'

Vue.filter('twoDecimal', val => parseFloat(val).toFixed(2).toString())

Vue.filter('noDecimal', val => Math.round(val))

Vue.filter('truncate', function (value, limit) {
    if (value.length > limit) {
        value = `${value.substring(0, (limit - 3))}...`;
    }

    return value
})

Vue.filter('readableValue', val => isNaN(val) ? val : parseFloat(val).toFixed(2).toString())

Vue.filter(
    'currency',
    (val, locale = 'it-IT', currency = 'EUR') => {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0
        })
        .format(val)
    })

Vue.filter(
    'thousandSeparator',
    (val) => {
        return Intl.NumberFormat().format(val)
    })

Vue.filter(
    'minutesToHours',
    (val) => {
        const num = val;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return rhours + ":" + rminutes + "h"
    })

Vue.filter(
    'minutesToHoursNoSymbol',
    (num) => {
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        return rhours + ":" + rminutes
    })

Vue.filter(
    'convertIntoMonthString',
    (val) => {
        const date = new Date(`${val}/10/2021`)
        const locale = "it-IT"
        const month = date.toLocaleString(locale, { month: "long" });
        return month.charAt(0).toUpperCase() + month.slice(1);
    })    

Vue.filter(
    'convertIntoMonthString2',
    (val) => {
        const date = new Date(val)
        const locale = "it-IT"
        const month = date.toLocaleString(locale, { month: "long" });
        return month.charAt(0).toUpperCase() + month.slice(1);
    })  

Vue.filter(
    'convertUnit',
    (unit) => {
        switch (unit) {
            case 'kwh':
              return 'kWh'
            case 'eur':
              return '€'
            default:
              return unit
          }
          
    })  
