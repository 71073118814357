import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fdcb6f84 = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _47ca0f46 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _3e75eb4f = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _6686aaf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _00bc6924 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _21f56a56 = () => interopDefault(import('../pages/plants/index.vue' /* webpackChunkName: "pages/plants/index" */))
const _90ed56c6 = () => interopDefault(import('../pages/plantsmonitoring/index.vue' /* webpackChunkName: "pages/plantsmonitoring/index" */))
const _c77cb1f8 = () => interopDefault(import('../pages/presentation.vue' /* webpackChunkName: "pages/presentation" */))
const _7db23a41 = () => interopDefault(import('../pages/revenues.vue' /* webpackChunkName: "pages/revenues" */))
const _518aed4c = () => interopDefault(import('../pages/rgis/index.vue' /* webpackChunkName: "pages/rgis/index" */))
const _52e89ee6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _638432de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _e9b71746 = () => interopDefault(import('../pages/maintenances/reports/index.vue' /* webpackChunkName: "pages/maintenances/reports/index" */))
const _f985e808 = () => interopDefault(import('../pages/maintenances/requests/index.vue' /* webpackChunkName: "pages/maintenances/requests/index" */))
const _3357d363 = () => interopDefault(import('../pages/plants/new.vue' /* webpackChunkName: "pages/plants/new" */))
const _5901a79a = () => interopDefault(import('../pages/rgis/new.vue' /* webpackChunkName: "pages/rgis/new" */))
const _90877266 = () => interopDefault(import('../pages/tools/curves-logs/index.vue' /* webpackChunkName: "pages/tools/curves-logs/index" */))
const _4075e5b9 = () => interopDefault(import('../pages/tools/downtimes/index.vue' /* webpackChunkName: "pages/tools/downtimes/index" */))
const _1ebfcfce = () => interopDefault(import('../pages/tools/k404/index.vue' /* webpackChunkName: "pages/tools/k404/index" */))
const _98b6fae2 = () => interopDefault(import('../pages/tools/k405/index.vue' /* webpackChunkName: "pages/tools/k405/index" */))
const _5a42abae = () => interopDefault(import('../pages/tools/k410/index.vue' /* webpackChunkName: "pages/tools/k410/index" */))
const _f2ec1b34 = () => interopDefault(import('../pages/tools/k451/index.vue' /* webpackChunkName: "pages/tools/k451/index" */))
const _3a3c2e1f = () => interopDefault(import('../pages/users/new.vue' /* webpackChunkName: "pages/users/new" */))
const _32cc5825 = () => interopDefault(import('../pages/maintenances/requests/plants-workers-list.vue' /* webpackChunkName: "pages/maintenances/requests/plants-workers-list" */))
const _77d85ca1 = () => interopDefault(import('../pages/maintenances/reports/_report/index.vue' /* webpackChunkName: "pages/maintenances/reports/_report/index" */))
const _533cd719 = () => interopDefault(import('../pages/maintenances/requests/_request/index.vue' /* webpackChunkName: "pages/maintenances/requests/_request/index" */))
const _37ebc491 = () => interopDefault(import('../pages/tools/downtimes/_downtimes/index.vue' /* webpackChunkName: "pages/tools/downtimes/_downtimes/index" */))
const _1f6c6bea = () => interopDefault(import('../pages/tools/k451/_plant/index.vue' /* webpackChunkName: "pages/tools/k451/_plant/index" */))
const _1a852e8b = () => interopDefault(import('../pages/maintenances/reports/_report/edit.vue' /* webpackChunkName: "pages/maintenances/reports/_report/edit" */))
const _6beb8513 = () => interopDefault(import('../pages/maintenances/requests/_request/edit.vue' /* webpackChunkName: "pages/maintenances/requests/_request/edit" */))
const _1125eeca = () => interopDefault(import('../pages/tools/downtimes/_downtimes/edit.vue' /* webpackChunkName: "pages/tools/downtimes/_downtimes/edit" */))
const _7d41da9a = () => interopDefault(import('../pages/events/_event/index.vue' /* webpackChunkName: "pages/events/_event/index" */))
const _ed043288 = () => interopDefault(import('../pages/plants/_plant/index.vue' /* webpackChunkName: "pages/plants/_plant/index" */))
const _120e6818 = () => interopDefault(import('../pages/plantsmonitoring/_plant/index.vue' /* webpackChunkName: "pages/plantsmonitoring/_plant/index" */))
const _76bd0a2c = () => interopDefault(import('../pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _a8a82f6a = () => interopDefault(import('../pages/plants/_plant/alarms/index.vue' /* webpackChunkName: "pages/plants/_plant/alarms/index" */))
const _4e61360a = () => interopDefault(import('../pages/plants/_plant/devices/index.vue' /* webpackChunkName: "pages/plants/_plant/devices/index" */))
const _3d6f15e0 = () => interopDefault(import('../pages/plants/_plant/edit.vue' /* webpackChunkName: "pages/plants/_plant/edit" */))
const _2814563e = () => interopDefault(import('../pages/rgis/_rgi/edit.vue' /* webpackChunkName: "pages/rgis/_rgi/edit" */))
const _b241b8c0 = () => interopDefault(import('../pages/users/_user/edit.vue' /* webpackChunkName: "pages/users/_user/edit" */))
const _cd8a91a6 = () => interopDefault(import('../pages/plants/_plant/devices/_device.vue' /* webpackChunkName: "pages/plants/_plant/devices/_device" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/documents",
    component: _fdcb6f84,
    name: "documents"
  }, {
    path: "/events",
    component: _47ca0f46,
    name: "events"
  }, {
    path: "/Login",
    component: _3e75eb4f,
    name: "Login"
  }, {
    path: "/logout",
    component: _6686aaf4,
    name: "logout"
  }, {
    path: "/notifications",
    component: _00bc6924,
    name: "notifications"
  }, {
    path: "/plants",
    component: _21f56a56,
    name: "plants"
  }, {
    path: "/plantsmonitoring",
    component: _90ed56c6,
    name: "plantsmonitoring"
  }, {
    path: "/presentation",
    component: _c77cb1f8,
    name: "presentation"
  }, {
    path: "/revenues",
    component: _7db23a41,
    name: "revenues"
  }, {
    path: "/rgis",
    component: _518aed4c,
    name: "rgis"
  }, {
    path: "/settings",
    component: _52e89ee6,
    name: "settings"
  }, {
    path: "/users",
    component: _638432de,
    name: "users"
  }, {
    path: "/maintenances/reports",
    component: _e9b71746,
    name: "maintenances-reports"
  }, {
    path: "/maintenances/requests",
    component: _f985e808,
    name: "maintenances-requests"
  }, {
    path: "/plants/new",
    component: _3357d363,
    name: "plants-new"
  }, {
    path: "/rgis/new",
    component: _5901a79a,
    name: "rgis-new"
  }, {
    path: "/tools/curves-logs",
    component: _90877266,
    name: "tools-curves-logs"
  }, {
    path: "/tools/downtimes",
    component: _4075e5b9,
    name: "tools-downtimes"
  }, {
    path: "/tools/k404",
    component: _1ebfcfce,
    name: "tools-k404"
  }, {
    path: "/tools/k405",
    component: _98b6fae2,
    name: "tools-k405"
  }, {
    path: "/tools/k410",
    component: _5a42abae,
    name: "tools-k410"
  }, {
    path: "/tools/k451",
    component: _f2ec1b34,
    name: "tools-k451"
  }, {
    path: "/users/new",
    component: _3a3c2e1f,
    name: "users-new"
  }, {
    path: "/maintenances/requests/plants-workers-list",
    component: _32cc5825,
    name: "maintenances-requests-plants-workers-list"
  }, {
    path: "/maintenances/reports/:report",
    component: _77d85ca1,
    name: "maintenances-reports-report"
  }, {
    path: "/maintenances/requests/:request",
    component: _533cd719,
    name: "maintenances-requests-request"
  }, {
    path: "/tools/downtimes/:downtimes",
    component: _37ebc491,
    name: "tools-downtimes-downtimes"
  }, {
    path: "/tools/k451/:plant",
    component: _1f6c6bea,
    name: "tools-k451-plant"
  }, {
    path: "/maintenances/reports/:report/edit",
    component: _1a852e8b,
    name: "maintenances-reports-report-edit"
  }, {
    path: "/maintenances/requests/:request/edit",
    component: _6beb8513,
    name: "maintenances-requests-request-edit"
  }, {
    path: "/tools/downtimes/:downtimes/edit",
    component: _1125eeca,
    name: "tools-downtimes-downtimes-edit"
  }, {
    path: "/events/:event",
    component: _7d41da9a,
    name: "events-event"
  }, {
    path: "/plants/:plant",
    component: _ed043288,
    name: "plants-plant"
  }, {
    path: "/plantsmonitoring/:plant",
    component: _120e6818,
    name: "plantsmonitoring-plant"
  }, {
    path: "/users/:user",
    component: _76bd0a2c,
    name: "users-user"
  }, {
    path: "/plants/:plant/alarms",
    component: _a8a82f6a,
    name: "plants-plant-alarms"
  }, {
    path: "/plants/:plant/devices",
    component: _4e61360a,
    name: "plants-plant-devices"
  }, {
    path: "/plants/:plant/edit",
    component: _3d6f15e0,
    name: "plants-plant-edit"
  }, {
    path: "/rgis/:rgi/edit",
    component: _2814563e,
    name: "rgis-rgi-edit"
  }, {
    path: "/users/:user/edit",
    component: _b241b8c0,
    name: "users-user-edit"
  }, {
    path: "/plants/:plant/devices/:device",
    component: _cd8a91a6,
    name: "plants-plant-devices-device"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
