interface Plant {
    /**
     * 
     * Incoming array of plants - from rest api
     * 
     */
    id: number;
    plant_type: string;
    name: string;
    description: string;
    url: string;
}

// interface PlantList extends Array<Plant> { }

// interface theObject {

// }

// export class PlantsListSerializer {
//     object: theObject | undefined | null;
//     data: PlantList | undefined | null;
//     constructor(object?: theObject, data?: Plant[]) {
//         this.object = object
//         this.data = data
//         if (this.data)
//             this.data[0].description
//     }
// }

interface classInput {
    object?: Object,
    data?: Plant[]
}

export class PlantsListSerializer {
    object: Object | undefined;
    data: Plant[] | undefined;
    
    constructor (input: classInput) {
        if (input.hasOwnProperty('object'))
            this.object = input.object
        else if (input.hasOwnProperty('data'))
            this.data = input.data
    }

    // isValid (): Boolean | void {
    //     if (this.object) return
    // }

    deserialize (): void {
        
    }
}
