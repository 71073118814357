<template>
    <section>
        <p>Si è verificato un problema e <b>non è stato possibile caricare l'account utente</b>.</p>
        <p style="margin-top: 1rem;">
            Prova a
            <br />
            <b-button type="is-primary" @click="logout">eseguire nuovamente il login</b-button>
        </p>
        <p style="margin-top: 1.2rem;">Se il problema persiste, contatta l'assistenza di Onda.</p>
    </section>
</template>

<script>
export default {
    props: ['error'],
    methods: {
        logout () {
            this.$store.dispatch('auth/logout')
        }
    }
}
</script>
