import { MutationTree, ActionTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface Document {
    id: number,
    name: string,
    document_type: 'procedures' | 'bills', // TODO al singolare....
    related_plants: Array<{
        id: number,
        name: string,
        description: string
    }>,
    created_at: string,
    presigned_url: string
}

interface addDocumentDTO {
    name: string,
    document_type: 'procedures' | 'bills', // TODO al singolare....
    related_plants: Array<number>
}

interface addDocumentResponse extends Document {}

export const state = () => ({
    procedures: [] as Array<Document>,
    bills: [] as Array<Document>
})

export type DocumentsState = ReturnType<typeof state>

export const mutations: MutationTree<DocumentsState> = {
    setProcedures (state, procedures) {
        state.procedures = procedures
    },
    setBills (state, bills) {
        state.bills = bills
    }
}

export const actions: ActionTree<DocumentsState, RootState> = {
    async getProcedures ({ commit }) {
        const url = apiUrl({ endpoint: 'documents', params: { doc_type: 'procedure' }})
        try {
            const procedures: Array<Document> = await this.$axios.$get(url)
            commit('setProcedures', procedures)
        } catch (err) {
            console.error(err)
        }
    },
    async getBills ({ commit }) {
        const url = apiUrl({ endpoint: 'documents', params: { doc_type: 'bill' }})
        try {
            const bills: Array<Document> = await this.$axios.$get(url)
            commit('setBills', bills)
        } catch (err) {
            console.error(err)
        }
    },
    async addDocument (context, data: any) {
        const url = apiUrl({ endpoint: 'documents', params: {} })
        try {
            const reqData: addDocumentDTO = {
                name: data.file.name,
                document_type: data.documentType,
                related_plants: [ data.relatedPlant ]
            }
            
            // get the digitalocean spaces' presigned url
            const { presigned_url }: addDocumentResponse = await this.$axios.$post(url, reqData)
            
            // upload the actual file
            const options = {
                headers: { 'Content-Type': data.file.type }
            }
            // delete bearer auth, oterwhise aws returns an error
            const instance = this.$axios.create()
            delete instance.defaults.headers.common['Authorization']

            await instance.put(presigned_url, data.file, options)
        }
        catch (err) {
            console.error(err)
            throw new Error(err)
        }
    }
}
