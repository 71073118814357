import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { RootState } from '~/store'
import { apiUrl } from '~/utils/url'

interface eventFromApi {
    id: number,
    plant_id: number,
    begin_at: string,
    end_at: string,
    event_type: number,
    tag: string,
    priority: number,
    description: string,
}

export const state = () => ({
    events: [] as eventFromApi[],
})

export type EventsState = ReturnType<typeof state>

export const getters: GetterTree<EventsState, RootState> = {
    eventById (state) {
        return (eventId: number) => state.events.find(m => m.id == eventId)
    },
}

export const mutations: MutationTree<EventsState> = {
    setEvents (state, events) {
        state.events = events
    }
}

export const actions: ActionTree<EventsState, RootState> = {
    async getEvents (context, params) {
        const url = apiUrl({ endpoint: 'getEvents', params: params || {} })
        try {
            const events = await this.$axios.$get(url)
            context.commit('setEvents', events)
        } catch (err) {
            console.error(err)
        }
    }
}
